unit GiftAidClaimsList;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseList,
  WEBLib.Actions,
  Data.DB,
  WEBLib.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  WEBLib.Toast,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  Vcl.Controls,
  WEBLib.Lists,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  smx.webcore.types,
  WEBLib.ExtCtrls, WEBLib.Miletus;

type
  TGiftAidClaims = class(TBaseListForm)
    MainDatasetId: TIntegerField;
    MainDatasetTransactionId: TStringField;
    MainDatasetClaimRef: TStringField;
    MainDatasetCorrelationId: TStringField;
    MainDatasetIRMark: TStringField;
    MainDatasetFirstDonationDate: TDateField;
    MainDatasetLastDonationDate: TDateField;
    MainDatasetSubmissionDate: TDateTimeField;
    MainDatasetEarliestSubmissionDate: TDateField;
    MainDatasetGiftAidClaim: TFloatField;
    MainDatasetClaimStatus: TStringField;
    MainDatasetLastStatusChange: TDateTimeField;
    MainDatasetSubmittedBy: TIntegerField;
    MainDatasetHMRCOfficial: TIntegerField;
    MainDatasetComments: TStringField;
    MainDatasetIRMark64: TStringField;
    MainDatasetPaymentReceivedDate: TDateField;
    MainDatasetDateAdded: TDateTimeField;
    MainDatasetAddedBy: TIntegerField;
    MainDatasetLastUpdatedBy: TIntegerField;
    MainDatasetLastUpdated: TDateTimeField;
    MainDatasetErrorHandling: TStringField;
    MainDatasetPreparedDate: TDateField;
    MainDatasetTaxYear: TDateField;
    MainDatasetAuthWaitReason: TStringField;
    MainDatasetHMRCRef: TStringField;
    MainDatasetSource: TStringField;
    MainDatasetSubmissionData: TStringField;
    GridRefresh: TTimer;
    ClaimsCount: TTimer;
    MainDatasetIRMarkReceipt: TStringField;
    SaveDlg: TMiletusSaveDialog;
    procedure WebFormDestroy(Sender: TObject);
    procedure DataTableGetCellChildren(Sender: TObject; ACol, ARow: Integer;
        AField: TField; AValue: string; AElement: TJSHTMLElementRecord);
    procedure GridRefreshTimer(Sender: TObject);
    procedure MainDatasetClaimStatusGetText(Sender: TField; var Text: string; DisplayText: Boolean);
    procedure MainDatasetExportedForREGetText(Sender: TField; var Text: string; DisplayText: Boolean);
    procedure MainDatasetGADAmountGetText(Sender: TField; var Text: string; DisplayText: Boolean);
    procedure MainDatasetGiftAidClaimGetText(Sender: TField; var Text: string; DisplayText: Boolean);
    procedure MainDatasetOtherAmountsGetText(Sender: TField; var Text: string; DisplayText: Boolean);
    procedure MainDatasetSubmissionDateGetText(Sender: TField; var Text: string; DisplayText: Boolean);
    procedure WebFormShow(Sender: TObject);
    procedure ClaimsCountTimer(Sender: TObject);
    procedure MainDatasetAfterOpen(DataSet: TDataSet);
  private
    { Private declarations }
    FId: integer;
    FClaimStatus: string;
    FScheduledRefreshDone: boolean;
    FSubmittingRefreshDone: boolean;
    FOutstandingClaims: integer;
    [async]
    procedure MarkAsPaid(const AId: Integer); async;
    [async]
    procedure SubmitThisClaim(const AId: Integer); async;
    [async]
    procedure CheckAllClaimsSubmitted; async;

    [async]
    procedure DownloadExportFile(const AId: Integer); async;

  protected
    function RecordViewOption: TViewOption; override;
    function GetEditFormClass: TFormClass; override;
    procedure PrepareForm; override;
    procedure LoadData; override;
    function InitSortCol: Integer; override;
    function InitSortDirection: TSortDirection; override;
    procedure AddRowActions(const ARecordId: string; AParentElement: TJSHTMLElement); override;
    function CreateGridInFormCreate: boolean; override;
    procedure RefreshControls;
    procedure CheckForCurrentSubmission;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  GiftAidClaims: TGiftAidClaims;

implementation

uses
  XData.Web.Client,
  WEBLib.WebCtrls,
  System.DateUtils,
  MainDataModule,
  smx.Web.Layout.Utils,
  GiftAidDetailsForm,
  smx.Web.Utils,
  smx.Web.Service.Consts;

{$R *.dfm}

procedure TGiftAidClaims.WebFormDestroy(Sender: TObject);
begin
  GridRefresh.Enabled := false;
  ClaimsCount.Enabled := false;
  inherited;
end;

procedure TGiftAidClaims.AddRowActions(const ARecordId: string; AParentElement: TJSHTMLElement);

  procedure MarkThisAsPaid(Sender: TObject);
  begin
    MarkAsPaid(ARecordId.ToInteger);
  end;

  procedure SubmitClaim(Sender: TObject);
  begin
    DataTable.Enabled := false;
    SubmitThisClaim(ARecordId.ToInteger);
  end;

  procedure ExportFile(Sender: TObject);
  begin
    DataTable.Enabled := false;
    DownloadExportFile(ARecordId.ToInteger);
  end;

var
  Span: THTMLSpan;
begin
  inherited;

  if (MainDatasetClaimStatus.Value = 'Confirmed') then
  begin
    Span := TLayoutUtils.RowActionSpan(AParentElement, 'fad fa-hand-holding-heart', 'Payment received');
    Span.OnClick := @MarkThisAsPaid;
  end
  //else if (MainDatasetClaimStatus.Value = 'Scheduled') then
  //begin
  //end
  else if (MainDatasetClaimStatus.Value = 'WaitingAuth') then
  begin

  end;

  Span := TLayoutUtils.RowActionSpan(AParentElement, 'fad fa-hand-receiving', 'Download Export File');
  Span.OnClick := @ExportFile;
end;

procedure TGiftAidClaims.DataTableGetCellChildren(Sender: TObject; ACol, ARow:
    Integer; AField: TField; AValue: string; AElement: TJSHTMLElementRecord);
begin
  inherited;

  if DataTable.Cells[ACol, ARow] = '1899-12-30' then
  begin
    DataTable.Cells[ACol, ARow] := ' ';
  end;

end;

function TGiftAidClaims.GetEditFormClass: TFormClass;
begin
  Result := TGiftAidDetails;
end;

function TGiftAidClaims.InitSortCol: Integer;
begin
  Result := 0;
end;

function TGiftAidClaims.InitSortDirection: TSortDirection;
begin
  Result := sdDesc;
end;

procedure TGiftAidClaims.LoadData;
begin
  CreateGridPlugin;
end;

procedure TGiftAidClaims.MainDatasetClaimStatusGetText(Sender: TField; var Text: string; DisplayText: Boolean);
begin
  if Text = 'Scheduled' then
     Text := 'Prepared'
  else
     Text := TUtils.SplitOnCaps((Sender as TStringField).AsString);
end;

procedure TGiftAidClaims.MainDatasetExportedForREGetText(Sender: TField; var Text: string; DisplayText: Boolean);
begin
  if not Sender.IsNull then
    Text := FormatDateTime(SYS_DATE_FORMAT, (Sender as TDateField).AsDateTime);
end;

procedure TGiftAidClaims.MainDatasetGADAmountGetText(Sender: TField; var Text: string; DisplayText: Boolean);
begin
  Text := FormatFloat(SYS_MONEY_FORMAT, (Sender as TFloatField).AsFloat);
end;

procedure TGiftAidClaims.MainDatasetGiftAidClaimGetText(Sender: TField; var Text: string; DisplayText: Boolean);
begin
  Text := FormatFloat(SYS_MONEY_FORMAT, (Sender as TFloatField).AsFloat);
end;

procedure TGiftAidClaims.MainDatasetOtherAmountsGetText(Sender: TField; var Text: string; DisplayText: Boolean);
begin
  Text := FormatFloat(SYS_MONEY_FORMAT, (Sender as TFloatField).AsFloat);
end;

procedure TGiftAidClaims.MainDatasetSubmissionDateGetText(Sender: TField; var Text: string; DisplayText: Boolean);
begin
  if not Sender.IsNull then
    Text := FormatDateTime(SYS_DATE_FORMAT, (Sender as TDateTimeField).AsDateTime);
end;

procedure TGiftAidClaims.MarkAsPaid(const AId: Integer);
begin
  if not MainDataset.Locate('Id', AId, []) then
  begin
    ShowMessage('please refresh the page and try again');
    Exit;
  end;

  MainDataset.Edit;
  MainDatasetClaimStatus.Value := 'PaymentReceived';
  MainDatasetPaymentReceivedDate.Value := Today;
  MainDatasetLastStatusChange.Value := Now;
  MainDataset.Post;
end;

procedure TGiftAidClaims.PrepareForm;
begin
  FIdField := 'Id';
end;

function TGiftAidClaims.RecordViewOption: TViewOption;
begin
  Result := TViewOption.voReadOnly;
end;

procedure TGiftAidClaims.SubmitThisClaim(const AId: Integer);
var
  lParams, lPreview: string;
  lRetval: TXDataClientResponse;
  lJobParams: JS.TJSObject;
begin
  FId := AId;
  FScheduledRefreshDone := false;
  FSubmittingRefreshDone := false;


  if MainDataset.Locate('Id', AId, []) then
  begin
    MainDataset.Edit;
    MainDatasetClaimStatus.Value := 'Submitting';
    MainDataset.Post;
  end;

  lJobParams := JS.TJSObject.new;
  lJobParams.Properties['ClaimId'] := AId;
  lJobParams.Properties['ErrorHandling'] := '';

  lParams := JS.TJSJSON.stringify(lJobParams);

  lRetval := Await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(IJOBSVC_SCHEDULEJOB,
    ['TClaimGiftAidJob', 'AnyAdminUser', lParams, 'Undecided', 'Production']));

  GridRefresh.Enabled := true;
end;

procedure TGiftAidClaims.WebFormShow(Sender: TObject);
begin
  inherited;
  LoadData;
  RefreshControls;
end;

function TGiftAidClaims.CreateGridInFormCreate: boolean;
begin
  result := false;
end;

procedure TGiftAidClaims.RefreshControls;
begin
  FGridPlugin.Load;
end;

procedure TGiftAidClaims.GridRefreshTimer(Sender: TObject);
begin
  inherited;
  CheckAllClaimsSubmitted;
end;

procedure TGiftAidClaims.ClaimsCountTimer(Sender: TObject);
begin
  if (FClaimStatus = 'Submitting') and (FSubmittingRefreshDone = False) then
  begin
    FSubmittingRefreshDone := True;
    RefreshControls;
  end
  else if (FClaimStatus = 'Confirmed') OR (FClaimStatus = 'PaymentReceived') then
  begin
    GridRefresh.Enabled := false;
    RefreshControls;
  end;

  ClaimsCount.Enabled := false;
end;

procedure TGiftAidClaims.CheckAllClaimsSubmitted;
var
  lRetval: TXDataClientResponse;
begin
  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync('IGiftAiderUtilsService.ClaimsOutstanding', [FId]));
  FClaimStatus  := JS.ToString(lRetval.ResultAsObject['value']);

  ClaimsCount.Enabled := true;
end;


procedure TGiftAidClaims.CheckForCurrentSubmission;
begin
  if MainDataset.Locate('ClaimStatus', 'Submitting', []) then
  begin
    FId := MainDataset.FieldByName('Id').AsInteger;
    GridRefresh.Enabled := true;
  end;

end;

procedure TGiftAidClaims.MainDatasetAfterOpen(DataSet: TDataSet);
begin
  inherited;
  CheckForCurrentSubmission;
end;

procedure TGiftAidClaims.DownloadExportFile(const AId: Integer);
var
  lRetval: TXDataClientResponse;
  lData: string;

begin
  lRetval := await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync('IGiftAiderUtilsService.CreateExportFile', [AId]));
  lData  := JS.ToString(lRetval.ResultAsObject['value']);

  Application.DownloadTextFile(lData, 'SubmissionsExport.csv');
end;

procedure TGiftAidClaims.LoadDFMValues;
begin
  inherited LoadDFMValues;

  MainDatasetId := TIntegerField.Create(Self);
  MainDatasetTransactionId := TStringField.Create(Self);
  MainDatasetClaimRef := TStringField.Create(Self);
  MainDatasetCorrelationId := TStringField.Create(Self);
  MainDatasetIRMark := TStringField.Create(Self);
  MainDatasetFirstDonationDate := TDateField.Create(Self);
  MainDatasetLastDonationDate := TDateField.Create(Self);
  MainDatasetSubmissionDate := TDateTimeField.Create(Self);
  MainDatasetEarliestSubmissionDate := TDateField.Create(Self);
  MainDatasetGiftAidClaim := TFloatField.Create(Self);
  MainDatasetClaimStatus := TStringField.Create(Self);
  MainDatasetLastStatusChange := TDateTimeField.Create(Self);
  MainDatasetSubmittedBy := TIntegerField.Create(Self);
  MainDatasetHMRCOfficial := TIntegerField.Create(Self);
  MainDatasetComments := TStringField.Create(Self);
  MainDatasetIRMark64 := TStringField.Create(Self);
  MainDatasetPaymentReceivedDate := TDateField.Create(Self);
  MainDatasetDateAdded := TDateTimeField.Create(Self);
  MainDatasetAddedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdatedBy := TIntegerField.Create(Self);
  MainDatasetLastUpdated := TDateTimeField.Create(Self);
  MainDatasetErrorHandling := TStringField.Create(Self);
  MainDatasetPreparedDate := TDateField.Create(Self);
  MainDatasetTaxYear := TDateField.Create(Self);
  MainDatasetAuthWaitReason := TStringField.Create(Self);
  MainDatasetHMRCRef := TStringField.Create(Self);
  MainDatasetSource := TStringField.Create(Self);
  MainDatasetSubmissionData := TStringField.Create(Self);
  MainDatasetIRMarkReceipt := TStringField.Create(Self);
  GridRefresh := TTimer.Create(Self);
  ClaimsCount := TTimer.Create(Self);
  SaveDlg := TMiletusSaveDialog.Create(Self);

  cbPageSize.BeforeLoadDFMValues;
  DataTable.BeforeLoadDFMValues;
  SearchButton.BeforeLoadDFMValues;
  MainDataset.BeforeLoadDFMValues;
  MainDatasetId.BeforeLoadDFMValues;
  MainDatasetTransactionId.BeforeLoadDFMValues;
  MainDatasetClaimRef.BeforeLoadDFMValues;
  MainDatasetCorrelationId.BeforeLoadDFMValues;
  MainDatasetIRMark.BeforeLoadDFMValues;
  MainDatasetFirstDonationDate.BeforeLoadDFMValues;
  MainDatasetLastDonationDate.BeforeLoadDFMValues;
  MainDatasetSubmissionDate.BeforeLoadDFMValues;
  MainDatasetEarliestSubmissionDate.BeforeLoadDFMValues;
  MainDatasetGiftAidClaim.BeforeLoadDFMValues;
  MainDatasetClaimStatus.BeforeLoadDFMValues;
  MainDatasetLastStatusChange.BeforeLoadDFMValues;
  MainDatasetSubmittedBy.BeforeLoadDFMValues;
  MainDatasetHMRCOfficial.BeforeLoadDFMValues;
  MainDatasetComments.BeforeLoadDFMValues;
  MainDatasetIRMark64.BeforeLoadDFMValues;
  MainDatasetPaymentReceivedDate.BeforeLoadDFMValues;
  MainDatasetDateAdded.BeforeLoadDFMValues;
  MainDatasetAddedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdatedBy.BeforeLoadDFMValues;
  MainDatasetLastUpdated.BeforeLoadDFMValues;
  MainDatasetErrorHandling.BeforeLoadDFMValues;
  MainDatasetPreparedDate.BeforeLoadDFMValues;
  MainDatasetTaxYear.BeforeLoadDFMValues;
  MainDatasetAuthWaitReason.BeforeLoadDFMValues;
  MainDatasetHMRCRef.BeforeLoadDFMValues;
  MainDatasetSource.BeforeLoadDFMValues;
  MainDatasetSubmissionData.BeforeLoadDFMValues;
  MainDatasetIRMarkReceipt.BeforeLoadDFMValues;
  GridRefresh.BeforeLoadDFMValues;
  ClaimsCount.BeforeLoadDFMValues;
  SaveDlg.BeforeLoadDFMValues;
  try
    SetEvent(Self, 'OnShow', 'WebFormShow');
    cbPageSize.Left := 39;
    cbPageSize.Top := 66;
    DataTable.Left := 55;
    DataTable.Top := 110;
    DataTable.Options.ResizeColumns := True;
    DataTable.Options.ScrollVertical := True;
    DataTable.Columns.Clear;
    with DataTable.Columns.Add do
    begin
      DataField := 'Id';
      Title := 'Id';
      TitleElementClassName := 'sorting_desc';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'ClaimRef';
      Title := 'Claim Ref';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'SubmissionDate';
      Title := 'Submission Date';
    end;
    with DataTable.Columns.Add do
    begin
      DataField := 'ClaimStatus';
      Title := 'Status';
    end;
    with DataTable.Columns.Add do
    begin
      ElementClassName := 'text-end';
      DataField := 'GiftAidClaim';
      Title := 'GiftAid Claim';
    end;
    with DataTable.Columns.Add do
    begin
      Title := 'Actions';
    end;
    SearchButton.Visible := False;
    MainDataset.EntitySetName := 'GiftAidClaim';
    MainDatasetId.SetParentComponent(MainDataset);
    MainDatasetId.Name := 'MainDatasetId';
    MainDatasetId.FieldName := 'Id';
    MainDatasetTransactionId.SetParentComponent(MainDataset);
    MainDatasetTransactionId.Name := 'MainDatasetTransactionId';
    MainDatasetTransactionId.FieldName := 'TransactionId';
    MainDatasetTransactionId.Size := 32;
    MainDatasetClaimRef.SetParentComponent(MainDataset);
    MainDatasetClaimRef.Name := 'MainDatasetClaimRef';
    MainDatasetClaimRef.FieldName := 'ClaimRef';
    MainDatasetClaimRef.Size := 32;
    MainDatasetCorrelationId.SetParentComponent(MainDataset);
    MainDatasetCorrelationId.Name := 'MainDatasetCorrelationId';
    MainDatasetCorrelationId.FieldName := 'CorrelationId';
    MainDatasetCorrelationId.Size := 32;
    MainDatasetIRMark.SetParentComponent(MainDataset);
    MainDatasetIRMark.Name := 'MainDatasetIRMark';
    MainDatasetIRMark.FieldName := 'IRMark';
    MainDatasetIRMark.Size := 32;
    MainDatasetFirstDonationDate.SetParentComponent(MainDataset);
    MainDatasetFirstDonationDate.Name := 'MainDatasetFirstDonationDate';
    MainDatasetFirstDonationDate.FieldName := 'FirstDonationDate';
    MainDatasetLastDonationDate.SetParentComponent(MainDataset);
    MainDatasetLastDonationDate.Name := 'MainDatasetLastDonationDate';
    MainDatasetLastDonationDate.FieldName := 'LastDonationDate';
    MainDatasetSubmissionDate.SetParentComponent(MainDataset);
    MainDatasetSubmissionDate.Name := 'MainDatasetSubmissionDate';
    MainDatasetSubmissionDate.FieldName := 'SubmissionDate';
    SetEvent(MainDatasetSubmissionDate, Self, 'OnGetText', 'MainDatasetSubmissionDateGetText');
    MainDatasetSubmissionDate.DisplayFormat := 'dd/mm/yyyy';
    MainDatasetEarliestSubmissionDate.SetParentComponent(MainDataset);
    MainDatasetEarliestSubmissionDate.Name := 'MainDatasetEarliestSubmissionDate';
    MainDatasetEarliestSubmissionDate.FieldName := 'EarliestSubmissionDate';
    MainDatasetGiftAidClaim.SetParentComponent(MainDataset);
    MainDatasetGiftAidClaim.Name := 'MainDatasetGiftAidClaim';
    MainDatasetGiftAidClaim.FieldName := 'GiftAidClaim';
    MainDatasetGiftAidClaim.Required := True;
    SetEvent(MainDatasetGiftAidClaim, Self, 'OnGetText', 'MainDatasetGiftAidClaimGetText');
    MainDatasetGiftAidClaim.DisplayFormat := '#,##0.00';
    MainDatasetClaimStatus.SetParentComponent(MainDataset);
    MainDatasetClaimStatus.Name := 'MainDatasetClaimStatus';
    MainDatasetClaimStatus.FieldName := 'ClaimStatus';
    SetEvent(MainDatasetClaimStatus, Self, 'OnGetText', 'MainDatasetClaimStatusGetText');
    MainDatasetClaimStatus.Size := 15;
    MainDatasetLastStatusChange.SetParentComponent(MainDataset);
    MainDatasetLastStatusChange.Name := 'MainDatasetLastStatusChange';
    MainDatasetLastStatusChange.FieldName := 'LastStatusChange';
    MainDatasetSubmittedBy.SetParentComponent(MainDataset);
    MainDatasetSubmittedBy.Name := 'MainDatasetSubmittedBy';
    MainDatasetSubmittedBy.FieldName := 'SubmittedBy';
    MainDatasetHMRCOfficial.SetParentComponent(MainDataset);
    MainDatasetHMRCOfficial.Name := 'MainDatasetHMRCOfficial';
    MainDatasetHMRCOfficial.FieldName := 'HMRCOfficial';
    MainDatasetComments.SetParentComponent(MainDataset);
    MainDatasetComments.Name := 'MainDatasetComments';
    MainDatasetComments.FieldName := 'Comments';
    MainDatasetComments.Size := 4096;
    MainDatasetIRMark64.SetParentComponent(MainDataset);
    MainDatasetIRMark64.Name := 'MainDatasetIRMark64';
    MainDatasetIRMark64.FieldName := 'IRMark64';
    MainDatasetIRMark64.Size := 28;
    MainDatasetPaymentReceivedDate.SetParentComponent(MainDataset);
    MainDatasetPaymentReceivedDate.Name := 'MainDatasetPaymentReceivedDate';
    MainDatasetPaymentReceivedDate.FieldName := 'PaymentReceivedDate';
    MainDatasetDateAdded.SetParentComponent(MainDataset);
    MainDatasetDateAdded.Name := 'MainDatasetDateAdded';
    MainDatasetDateAdded.FieldName := 'DateAdded';
    MainDatasetDateAdded.Required := True;
    MainDatasetAddedBy.SetParentComponent(MainDataset);
    MainDatasetAddedBy.Name := 'MainDatasetAddedBy';
    MainDatasetAddedBy.FieldName := 'AddedBy';
    MainDatasetAddedBy.Required := True;
    MainDatasetLastUpdatedBy.SetParentComponent(MainDataset);
    MainDatasetLastUpdatedBy.Name := 'MainDatasetLastUpdatedBy';
    MainDatasetLastUpdatedBy.FieldName := 'LastUpdatedBy';
    MainDatasetLastUpdated.SetParentComponent(MainDataset);
    MainDatasetLastUpdated.Name := 'MainDatasetLastUpdated';
    MainDatasetLastUpdated.FieldName := 'LastUpdated';
    MainDatasetErrorHandling.SetParentComponent(MainDataset);
    MainDatasetErrorHandling.Name := 'MainDatasetErrorHandling';
    MainDatasetErrorHandling.FieldName := 'ErrorHandling';
    MainDatasetErrorHandling.Size := 16;
    MainDatasetPreparedDate.SetParentComponent(MainDataset);
    MainDatasetPreparedDate.Name := 'MainDatasetPreparedDate';
    MainDatasetPreparedDate.FieldName := 'PreparedDate';
    MainDatasetTaxYear.SetParentComponent(MainDataset);
    MainDatasetTaxYear.Name := 'MainDatasetTaxYear';
    MainDatasetTaxYear.FieldName := 'TaxYear';
    MainDatasetAuthWaitReason.SetParentComponent(MainDataset);
    MainDatasetAuthWaitReason.Name := 'MainDatasetAuthWaitReason';
    MainDatasetAuthWaitReason.FieldName := 'AuthWaitReason';
    MainDatasetAuthWaitReason.Size := 13;
    MainDatasetHMRCRef.SetParentComponent(MainDataset);
    MainDatasetHMRCRef.Name := 'MainDatasetHMRCRef';
    MainDatasetHMRCRef.FieldName := 'HMRCRef';
    MainDatasetSource.SetParentComponent(MainDataset);
    MainDatasetSource.Name := 'MainDatasetSource';
    MainDatasetSource.FieldName := 'Source';
    MainDatasetSource.Required := True;
    MainDatasetSource.Size := 15;
    MainDatasetSubmissionData.SetParentComponent(MainDataset);
    MainDatasetSubmissionData.Name := 'MainDatasetSubmissionData';
    MainDatasetSubmissionData.FieldName := 'SubmissionData';
    MainDatasetSubmissionData.Size := 500;
    MainDatasetIRMarkReceipt.SetParentComponent(MainDataset);
    MainDatasetIRMarkReceipt.Name := 'MainDatasetIRMarkReceipt';
    MainDatasetIRMarkReceipt.FieldName := 'IRMarkReceipt';
    MainDatasetIRMarkReceipt.Size := 500;
    GridRefresh.SetParentComponent(Self);
    GridRefresh.Name := 'GridRefresh';
    GridRefresh.Enabled := False;
    GridRefresh.Interval := 15000;
    SetEvent(GridRefresh, Self, 'OnTimer', 'GridRefreshTimer');
    GridRefresh.Left := 24;
    GridRefresh.Top := 424;
    ClaimsCount.SetParentComponent(Self);
    ClaimsCount.Name := 'ClaimsCount';
    ClaimsCount.Enabled := False;
    SetEvent(ClaimsCount, Self, 'OnTimer', 'ClaimsCountTimer');
    ClaimsCount.Left := 104;
    ClaimsCount.Top := 424;
    SaveDlg.SetParentComponent(Self);
    SaveDlg.Name := 'SaveDlg';
    SaveDlg.Left := 544;
    SaveDlg.Top := 408;
  finally
    cbPageSize.AfterLoadDFMValues;
    DataTable.AfterLoadDFMValues;
    SearchButton.AfterLoadDFMValues;
    MainDataset.AfterLoadDFMValues;
    MainDatasetId.AfterLoadDFMValues;
    MainDatasetTransactionId.AfterLoadDFMValues;
    MainDatasetClaimRef.AfterLoadDFMValues;
    MainDatasetCorrelationId.AfterLoadDFMValues;
    MainDatasetIRMark.AfterLoadDFMValues;
    MainDatasetFirstDonationDate.AfterLoadDFMValues;
    MainDatasetLastDonationDate.AfterLoadDFMValues;
    MainDatasetSubmissionDate.AfterLoadDFMValues;
    MainDatasetEarliestSubmissionDate.AfterLoadDFMValues;
    MainDatasetGiftAidClaim.AfterLoadDFMValues;
    MainDatasetClaimStatus.AfterLoadDFMValues;
    MainDatasetLastStatusChange.AfterLoadDFMValues;
    MainDatasetSubmittedBy.AfterLoadDFMValues;
    MainDatasetHMRCOfficial.AfterLoadDFMValues;
    MainDatasetComments.AfterLoadDFMValues;
    MainDatasetIRMark64.AfterLoadDFMValues;
    MainDatasetPaymentReceivedDate.AfterLoadDFMValues;
    MainDatasetDateAdded.AfterLoadDFMValues;
    MainDatasetAddedBy.AfterLoadDFMValues;
    MainDatasetLastUpdatedBy.AfterLoadDFMValues;
    MainDatasetLastUpdated.AfterLoadDFMValues;
    MainDatasetErrorHandling.AfterLoadDFMValues;
    MainDatasetPreparedDate.AfterLoadDFMValues;
    MainDatasetTaxYear.AfterLoadDFMValues;
    MainDatasetAuthWaitReason.AfterLoadDFMValues;
    MainDatasetHMRCRef.AfterLoadDFMValues;
    MainDatasetSource.AfterLoadDFMValues;
    MainDatasetSubmissionData.AfterLoadDFMValues;
    MainDatasetIRMarkReceipt.AfterLoadDFMValues;
    GridRefresh.AfterLoadDFMValues;
    ClaimsCount.AfterLoadDFMValues;
    SaveDlg.AfterLoadDFMValues;
  end;
end;

end.

