{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2021 - 2024                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.Miletus;

interface

uses
  Classes, js, web, WEBLib.JSON, WEBLib.Forms, WEBLib.Menus, Types,
  WEBLib.Graphics, SysUtils, WEBLib.Dialogs;

type
  TMiletusObserverChangeEvent = procedure(Sender: TObject; AMessageID: Integer; AMessageData: TJSONValue) of object;

  TMiletusObserver = class(TComponent)
  private
    FObserverEnabled: Boolean;
    FOnObserverChange: TMiletusObserverChangeEvent;
  protected
    procedure ObserverChange(AMessageID: Integer; AMessageData: TJSONValue); virtual;
    property ObserverEnabled: Boolean read FObserverEnabled write FObserverEnabled default True;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    property OnObserverChange: TMiletusObserverChangeEvent read FOnObserverChange write FOnObserverChange;
  end;

  TMiletusINIFile = class(TObject)
  private
    FFileName: string;
    FGUID: string;
    FObserver: TMiletusObserver;
    FReadBoolRes: Boolean;
    FReadDateRes: TDateTime;
    FReadDateTimeRes: TDateTime;
    FReadFloatRes: Double;
    FReadInt64Res: Int64;
    FReadIntegerRes: Integer;
    FReadStringRes: string;
    FReadStreamRes: TStream;
    FReadStringsRes: TStrings;
    FReadTimeRes: TDateTime;
    FReadValueProc: TProc;
  protected
    procedure InternalWriteValue(const AType: Integer; const Section, NameOrIdent: string; Value: JSValue);
    procedure DoObserverChange(Sender: TObject; AMessageID: Integer; AData: TJSONValue);
  public
    constructor Create(AFileName: string);
    destructor Destroy; override;
    procedure DeleteKey(const Section, Ident: string);
    procedure EraseSection(const Section: string);
    function SectionExists(const Section: string): TJSPromise;
    function ValueExists(const Section, Ident: string): TJSPromise;
    function ReadBool(const Section, Ident: string; Default: Boolean): TJSPromise;
    function ReadDate(const Section, Name: string; Default: TDateTime): TJSPromise;
    function ReadDateTime(const Section, Name: string; Default: TDateTime): TJSPromise;
    function ReadFloat(const Section, Name: string; Default: Double): TJSPromise;
    function ReadInt64(const Section, Ident: string; Default: Int64): TJSPromise;
    function ReadInteger(const Section, Ident: string; Default: Integer): TJSPromise;
    function ReadString(const Section, Ident: string; Default: string): TJSPromise;
    function ReadSection(const Section: string; Strings: TStrings): TJSPromise;
    function ReadSections(Strings: TStrings): TJSPromise;
    function ReadSectionValues(const Section: string; Strings: TStrings): TJSPromise;
    function ReadSubSections(const Section: string; Strings: TStrings; Recurse: Boolean = False): TJSPromise;
    function ReadTime(const Section, Name: string; Default: TDateTime): TJSPromise;
    function ReadBinaryStream(const Section, Name: string; Value: TStream): TJSPromise;
    procedure WriteBool(const Section, Ident: string; Value: Boolean);
    procedure WriteDate(const Section, Name: string; Value: TDateTime);
    procedure WriteDateTime(const Section, Name: string; Value: TDateTime);
    procedure WriteFloat(const Section, Name: string; Value: Double);
    procedure WriteInt64(const Section, Ident: string; Value: Int64);
    procedure WriteInteger(const Section, Ident: string; Value: Integer);
    procedure WriteString(const Section, Ident, Value: String);
    procedure WriteTime(const Section, Name: string; Value: TDateTime);
    procedure WriteBinaryStream(const Section, Name: string; Value: TStream);
  end;

  TMiletusRegistryRootKey = NativeInt;

  TMiletusRegistry = class(TObject)
  private
    FAccess: LongWord;
    FReject: Boolean;
    FRootKey: TMiletusRegistryRootKey;
    FGUID: string;
    FObserver: TMiletusObserver;
    FReadValueProc: TProc;
    FReadBoolRes: Boolean;
    FReadDateTimeRes: TDateTime;
    FReadFloatRes: Double;
    FReadIntegerRes: Integer;
    FReadStringRes: string;
    FReadStringsRes: TStrings;
    FReadBytesRes: TBytes;
  protected
    procedure InternalWriteValue(const AType: Integer; const NameOrIdent: string; Value: JSValue);
    procedure DoObserverChange(Sender: TObject; AMessageID: Integer; AData: TJSONValue);
  public
    constructor Create; overload;
    constructor Create(AAccess: LongWord); overload;
    destructor Destroy; override;
    function CloseKey: TJSPromise; //jsvalue, undefined
    function CreateKey(const Key: string): TJSPromise; //Boolean
    function DeleteKey(const Key: string): TJSPromise; //Boolean
    function DeleteValue(const Name: string): TJSPromise; //Boolean
    function KeyExists(const Key: string): TJSPromise; //Boolean
    function OpenKey(const Key: string; CanCreate: Boolean): TJSPromise;
    function ValueExists(const Name: string): TJSPromise; //Boolean
    function ReadCurrency(const Name: string): TJSPromise; //Currency;
    function ReadBinaryData(const Name: string; var Buffer: TBytes; BufSize: Integer): TJSPromise; //Integer;
    function ReadBool(const Name: string): TJSPromise; //Boolean;
    function ReadDate(const Name: string): TJSPromise; //TDateTime;
    function ReadDateTime(const Name: string): TJSPromise; //TDateTime;
    function ReadFloat(const Name: string): TJSPromise; //Double;
    function ReadInteger(const Name: string): TJSPromise; //Integer;
    function ReadString(const Name: string): TJSPromise; //string;
    function ReadTime(const Name: string): TJSPromise; //TDateTime;
    procedure WriteCurrency(const Name: string; Value: Currency);
    procedure WriteBinaryData(const Name: string; const Buffer: TBytes; BufSize: Integer);
    procedure WriteBool(const Name: string; Value: Boolean);
    procedure WriteDate(const Name: string; Value: TDateTime);
    procedure WriteDateTime(const Name: string; Value: TDateTime);
    procedure WriteFloat(const Name: string; Value: Double);
    procedure WriteInteger(const Name: string; Value: Integer);
    procedure WriteString(const Name, Value: string);
    procedure WriteExpandString(const Name, Value: string);
    procedure WriteTime(const Name: string; Value: TDateTime);
    property RootKey: TMiletusRegistryRootKey read FRootKey write FRootKey;
    property Access: LongWord read FAccess write FAccess;
  end;

  //TMiletusStringListLoadFromFileProc = reference to procedure;
  TMiletusLoadFileProc = reference to procedure;

  TMiletusStringList = class(TStringList)
  private
    FGUID: string;
    FObserver: TMiletusObserver;
    FLoadProc: TMiletusLoadFileProc;
  protected
    procedure InternalSendToFile(const FileName: string; ASync: Boolean);
    procedure DoStringListResponse(Sender: TObject; AMessageID: Integer; AData: TJSONValue);
  public
    constructor Create; reintroduce;
    destructor Destroy; override;
    procedure LoadFromFile(const FileName: string); virtual;
    procedure LoadFromFileAsync(const FileName: string; AProc: TMiletusLoadFileProc); virtual; reintroduce;
    procedure SaveToFile(const FileName: string); override;
    procedure SaveToFileAsync(const FileName: string);
  end;

  TMiletusBinaryDataStream = class(TPersistent)
  private
    FGUID: string;
//    FText: string;
    FBase64: string;
    FObserver: TMiletusObserver;
    FLoadProc: TMiletusLoadFileProc;
    function GetArrayBuffer: TJSArrayBuffer;
    procedure SetArrayBuffer(const Value: TJSArrayBuffer);
    function GetText: string;
    procedure SetText(const Value: string);
    function GetAsStream: TStream;
    procedure SetAsStream(const Value: TStream);
  protected
    procedure InternalSaveToFile(const FileName: string; ASync: Boolean);
    procedure DoStringListResponse(Sender: TObject; AMessageID: Integer; AData: TJSONValue);
    function HandleResponse(Event: TEventListenerEvent): Boolean;
    function HandleAbort(Event: TEventListenerEvent): Boolean;
  public
    constructor Create;
    destructor Destroy; override;
    procedure LoadFromFile(const FileName: string); virtual;
    procedure LoadFromFileAsync(const FileName: string; AProc: TMiletusLoadFileProc); virtual;
    procedure LoadFromFileRequest(const FileName: string; AProc: TMiletusLoadFileProc); virtual;
    procedure SaveToFile(const FileName: string); virtual;
    procedure SaveToFileAsync(const FileName: string); virtual;
    procedure SaveToFileRequest(const FileName: string); virtual;
    property AsArrayBuffer: TJSArrayBuffer read GetArrayBuffer write SetArrayBuffer;
    property AsStream: TStream read GetAsStream write SetAsStream;
    property Base64: string read FBase64 write FBase64;
    property Text: string read GetText write SetText;
  end;

  TMiletusStream = class(TMemoryStream)
  public
    procedure LoadFromFile(AFilename: string); reintroduce;
    procedure SaveToFile(AFileName: string);
  end;

  TMiletusDialogProc = reference to procedure(FileName: string);
  TMiletusDialogExecute = procedure(Sender: TObject; AFileName: string) of object;

  TMiletusOpenDialog = class(TMiletusObserver)
  private
    FGUID: string;
    FResponseProc: TMiletusDialogProc;
    FFileName: string;
    FFilterIndex: Integer;
    FDefaultExt: string;
    FTitle: string;
    FFilter: string;
    FInitialDir: string;
    FFiles: TStrings;
    FOptions: TOpenOptions;
    FOnExecute: TMiletusDialogExecute;
    procedure SetOptions(const Value: TOpenOptions);
  protected
    FOpenID: Integer;
    procedure ObserverChange(AMessageID: Integer; AMessageData: TJSONValue); override;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure Execute(AProc: TMiletusDialogProc); overload; virtual;
    function Execute(ASync: Boolean = True): Boolean; overload; virtual;
    property Files: TStrings read FFiles write FFiles;
  published
    property DefaultExt: string read FDefaultExt write FDefaultExt;
    property FileName: string read FFileName write FFileName;
    property Filter: string read FFilter write FFilter;
    property FilterIndex: Integer read FFilterIndex write FFilterIndex;
    property InitialDir: string read FInitialDir write FInitialDir;
    property Options: TOpenOptions read FOptions write SetOptions;
    property Title: string read FTitle write FTitle;
    property OnExecute: TMiletusDialogExecute read FOnExecute write FOnExecute;
  end;

  TMiletusSaveDialog = class(TMiletusOpenDialog)
  public
    constructor Create(AOwner: TComponent); override;
  end;

  TMiletusMessageBoxProc = reference to procedure(ACheckBoxChecked: Boolean);
  TMiletusMessageBoxType = (mbtNone, mbtInfo, mbtError, mbtQuestion, mbtWarning);
  TMiletusMessageBoxExecute = procedure(Sender: TObject; ACheckboxChecked: Boolean) of object;

  TMiletusMessageBox = class(TMiletusObserver)
  private
    FGUID: string;
    FMessageProc: TMiletusMessageBoxProc;
    FButtons: TStringList;
    FVerificationChecked: Boolean;
    FVerificationText: string;
    FTitle: string;
    FText: string;
    FNoLink: Boolean;
    FResponse: NativeInt;
    FCaption: string;
    FType: TMiletusMessageBoxType;
    FOnExecute: TMiletusMessageBoxExecute;
    procedure SetButtons(const Value: TStringList);
  protected
    procedure ObserverChange(AMessageID: Integer; AMessageData: TJSONValue); override;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure Execute(AProc: TMiletusMessageBoxProc = nil);
    procedure ExecuteSync;
  published
    property Buttons: TStringList read FButtons write SetButtons;
    property VerificationChecked: Boolean read FVerificationChecked write FVerificationChecked;
    property VerificationText: string read FVerificationText write FVerificationText;
    property Title: string read FTitle write FTitle;
    property DialogType: TMiletusMessageBoxType read FType write FType;
    property Text: string read FText write FText;
    property NoLink: Boolean read FNoLink write FNoLink;
    property Response: NativeInt read FResponse write FResponse;
    property Caption: string read FCaption write FCaption;
    property OnExecute: TMiletusMessageBoxExecute read FOnExecute write FOnExecute;
  end;

  TMiletusErrorBox = class(TComponent)
  private
    FContent: string;
    FTitle: string;
  public
    procedure Execute;
  published
    property Content: string read FContent write FContent;
    property Title: string read FTitle write FTitle;
  end;

  TMiletusMainMenu = class(TCustomMainMenu)
  private
    FGUID: string;
    FObserver: TMiletusObserver;
  protected
    procedure GenerateMainMenu;
    procedure MenuItemClick(AIndexes: string; AChecked: Boolean = False);
    procedure DoReceiveMenuResponse(Sender: TObject; AMessageID: Integer; AData: TJSONValue);
  public
    constructor Create(AOwner: TComponent); override;
    function CreateElement: TJSElement; override;
    destructor Destroy; override;
    procedure EndUpdate; override;
  end;

  TMiletusPopupMenu = class(TCustomMainMenu)
  private
    FGUID: string;
    FObserver: TMiletusObserver;
    FOnPopup: TNotifyEvent;
  protected
    function GeneratePopupMenu(AX, AY: Integer): TJSONObject;
    procedure MenuItemClick(AIndexes: string; AChecked: Boolean = False);
    procedure DoReceivePopupMenuResponse(Sender: TObject; AMessageID: Integer; AData: TJSONValue);
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure CloseMenu;
    procedure Popup(X, Y: Integer);
    property OnPopup: TNotifyEvent read FOnPopup write FOnPopup;
  end;

  TMiletusFileWatch = class(TCollectionItem)
  private
    FFileName: string;
    FOnChange: TNotifyEvent;
    procedure SetFileName(const Value: string);
  public
    constructor Create(ACollection: TCollection); override;
  published
    property FileName: string read FFileName write SetFileName;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  TMiletusFileWatches = class(TOwnedCollection)
  private
    function GetItem(Index: Integer): TMiletusFileWatch; reintroduce;
    procedure SetItem(Index: Integer; const Value: TMiletusFileWatch); reintroduce;
  public
    function Add: TMiletusFileWatch; reintroduce;
    property Items[Index: Integer]: TMiletusFileWatch read GetItem write SetItem;
  end;

  TMiletusFileWatcher = class(TMiletusObserver)
  private
    FFiles: TMiletusFileWatches;
  protected
    procedure ObserverChange(AMessageID: Integer; AMessageData: TJSONValue); override;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
  published
    property Files: TMiletusFileWatches read FFiles write FFiles;
  end;

  TMiletusShortcut = class(TCollectionItem)
  private
    FShortcut: TShortcut;
    FOnChange: TNotifyEvent;
    procedure SetShortcut(const Value: TShortcut);
  public
    destructor Destroy; override;
  published
    property Shortcut: TShortcut read FShortcut write SetShortcut;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  TMiletusShortcuts = class(TOwnedCollection)
  private
    function GetItem(Index: Integer): TMiletusShortcut; reintroduce;
    procedure SetItem(Index: Integer; const Value: TMiletusShortcut); reintroduce;
  public
    function Add: TMiletusShortcut; reintroduce;
    property Items[Index: Integer]: TMiletusShortcut read GetItem write SetItem;
  end;

  TMiletusGlobalShortcuts = class(TMiletusObserver)
  private
    FShortcuts: TMiletusShortcuts;
  protected
    procedure ObserverChange(AMessageID: Integer; AMessageData: TJSONValue); override;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
  published
    property Shortcuts: TMiletusShortcuts read FShortcuts write FShortcuts;
  end;

  TMiletusNotification = class(TPersistent)
  public
    Name: string;
    Title: string;
    AlertBody: string;
  end;

  TMiletusNotificationCenter = class(TComponent)
  public
    function CreateNotification: TMiletusNotification; overload;
    function CreateNotification(const AName, AAlertBody: string): TMiletusNotification; overload;
    procedure PresentNotification(const ANotification: TMiletusNotification);
  end;

  TMiletusTrayIcon = class(TMiletusObserver)
  private
    FPopupMenu: TMiletusPopupMenu;
    FHint: string;
    FOnClick: TNotifyEvent;
    FIconURL: string;
    procedure SetHint(const Value: string);
  protected
    procedure Notification(AComponent: TComponent; Operation: TOperation); override;
    procedure DoCreateTrayIcon;
    procedure ObserverChange(AMessageID: Integer; AMessageData: TJSONValue); override;
  public
    constructor Create(AOwner: TComponent); override;
    procedure EndUpdate; override;
  published
    property Hint: string read FHint write SetHint;
    property IconURL: string read FIconURL write FIconURL;
    property PopupMenu: TMiletusPopupMenu read FPopupMenu write FPopupMenu;
    property OnClick: TNotifyEvent read FOnClick write FOnClick;
  end;

  TMiletusClipboard = class(TPersistent)
  private
    function GetAsText: string;
    procedure SetAsText(const Value: string);
  public
    function HasFormat(AFormat: Word): Boolean;
    function HasFormatP(AFormat: Word): TJSPromise;
    function GetAsTextP: TJSPromise;
    property AsText: string read GetAsText write SetAsText;
  end;

  TMiletusShell = class(TPersistent)
  public
    procedure Beep;
    procedure OpenExternal(URL: string);
    procedure OpenItem(FullPath: string);
    procedure MoveItemToTrash(FullPath: string);
    procedure ShowItemInFolder(FullPath: string);
    function Execute(ACommand: string; AWorkingDir: string = ''): TJSPromise;
  end;

  TMiletusWindow = class(TMiletusObserver)
  private
    FFormClass: TFormClass;
    FOnShow: TNotifyEvent;
    FOnHide: TNotifyEvent;
    FOnMaximize: TNotifyEvent;
    FOnMinimize: TNotifyEvent;
    FOnClose: TNotifyEvent;
    FOnResize: TNotifyEvent;
  protected
    procedure ObserverChange(AMessageID: Integer; AMessageData: TJSONValue); override;
  public
    procedure Close;
    procedure Hide;
    procedure LoadFromURL(URL: string);
    procedure Show;
    procedure ShowModal;
    property FormClass: TFormClass read FFormClass write FFormClass;
  published
    property OnClose: TNotifyEvent read FOnClose write FOnClose;
    property OnShow: TNotifyEvent read FOnShow write FOnShow;
    property OnHide: TNotifyEvent read FOnHide write FOnHide;
    property OnMinimize: TNotifyEvent read FOnMinimize write FOnMinimize;
    property OnMaximize: TNotifyEvent read FOnMaximize write FOnMaximize;
    property OnResize: TNotifyEvent read FOnResize write FOnResize;
  end;

  TMiletusUpdate = class(TComponent)
  private
    FURL: string;
    FPassword: string;
    FUsername: string;
    FEnable: Boolean;
    FLogFilename: string;
  public
    function NewVersionAvailable: TJSPromise; //boolean
    function DoUpdate: TJSPromise; //resolve when updated?
    procedure Restart;
  published
    property URL: string read FURL write FURL;
    property Username: string read FUsername write FUsername;
    property Password: string read FPassword write FPassword;
    property LogFilename: string read FLogFilename write FLogFilename;
    property EnableLogging: Boolean read FEnable write FEnable;
  end;

  TMiletusFormMessageEvent = procedure(Sender: TObject; FormID, AMessage: string) of object;

  TMiletusFormBorderStyle = (bsNoneBorder, bsSingleBorder, bsSizeableBorder, bsDialogBorder, bsToolWindowBorder, bsSizeToolWinBorder);
  TDefaultMonitor = (dmDesktop, dmPrimary, dmMainForm, dmActiveForm);
  TBorderIcon = (biSystemMenu, biMinimize, biMaximize, biHelp);
  TBorderIcons = set of TBorderIcon;

const
  DefaultBorderIcons = [biSystemMenu, biMinimize, biMaximize];

type

  TMiletusForm = class(TWebForm)
  private
    FFormID: string;
    FObserver: TMiletusObserver;
    FOnFormMessage: TMiletusFormMessageEvent;
    FScreenSnap: Boolean;
    FDefaultMonitor: TDefaultMonitor;
    FWindowState: TWindowState;
    FBorderIcons: TBorderIcons;
    FBorderStyle: TMiletusFormBorderStyle;
    FSnapBuffer: Integer;
    {$IFDEF FREEWARE}
    FNotifyCount: integer;
    {$ENDIF}
    procedure SetBorderIcons(const Value: TBorderIcons);
    procedure SetDefaultMonitor(const Value: TDefaultMonitor);
    procedure SetScreenSnap(const Value: Boolean);
    procedure SetWindowState(const Value: TWindowState);
    procedure SetBorderStyle(const Value: TMiletusFormBorderStyle);
    procedure SetSnapBuffer(const Value: Integer);
    procedure SetBorderWidth(const Value: integer); override;
    procedure SetCaption(const AValue: string); override;
    procedure SetFormStyle(const Value: TFormStyle); override;
    procedure SetHeight(AValue: Integer); override;
    procedure SetPosition(const Value: TPosition); override;
    procedure SetWidth(AValue: Integer); override;
  protected
    procedure DoReceiveFormMessage(Sender: TObject; AMessageID: Integer; AData: TJSONValue);
    procedure LoadedDone; override;
    procedure InternalSendMessageToMiletus(AMessageID: Integer; AMessageData: TJSONObject); virtual;
  public
    procedure CreateInitialize; override;
    procedure Close; override;
    procedure EndUpdate; override;
    procedure RegisterForm(FormID: string);
    procedure SendMessage(FormID, AMessage: string);
  published
    property BorderStyle: TMiletusFormBorderStyle read FBorderStyle write SetBorderStyle default bsSizeableBorder;
    property BorderIcons: TBorderIcons read FBorderIcons write SetBorderIcons default DefaultBorderIcons;
    property BorderWidth default 0;
    property DefaultMonitor: TDefaultMonitor read FDefaultMonitor write SetDefaultMonitor default dmActiveForm;
    property FormStyle;
    property Position;
    property ScreenSnap: Boolean read FScreenSnap write SetScreenSnap default false;
    property SnapBuffer: Integer read FSnapBuffer write SetSnapBuffer default 10;
    property WindowState: TWindowState read FWindowState write SetWindowState default wsNormal;
    property OnFormMessage: TMiletusFormMessageEvent read FOnFormMessage write FOnFormMessage;
  end;

  TMiletusCommunicationReceiveMessageEvent = procedure(AMessageID: Integer; AMessageData: TJSONValue) of object;
  TMiletusCommunicationCustomMessageEvent = procedure(AMessageID: Integer; AMessage: string) of object;
  TMiletusCommunicationCustomMessageAEvent = reference to procedure(AMessageID: Integer; AMessage: string);

  TMiletusCommunication = class(TPersistent)
  private
    FObservers: TList;
    FOnCustomMessage: TMiletusCommunicationCustomMessageEvent;
    FOnCustomMessageA: TMiletusCommunicationCustomMessageAEvent;
  protected
    procedure Change(AMessageID: Integer; AMessageData: TJSONValue);
    procedure DoHandleVersionInfo(AMessageData: TJSONValue);
    procedure DoHandleAppParams(AMessageData: TJSONValue);
    procedure DoHandleCursorPos(AMessageData: TJSONValue);
    procedure DoHandlePaths(AMessageData: TJSONValue);
    procedure DoHandleClipboardFormat(AMessageData: TJSONValue);
    procedure DoHandleClipboardText(AMessageData: TJSONValue);
    procedure DoHandleOSVersion(AMessageData: TJSONValue);
    procedure DoHandleFileList(AMessageData: TJSONValue);
    procedure DoHandleDirList(AMessageData: TJSONValue);
    procedure DoHandleShellExecute(AMessageData: TJSONValue);
    procedure DoHandleDLLProc(AMessageData: TJSONValue);
    procedure DoHandleDLLFunc(AMessageData: TJSONValue);
    procedure DoHandleDLLLoad(AMessageData: TJSONValue);
    procedure DoHandleCustomDLLMsg(AMessageData: TJSONValue);
    procedure DoHandleUpdateStart;
    procedure DoHandleUpdateCheck(AMessageData: TJSONValue);
  public
    constructor Create;
    destructor Destroy; override;
    procedure RegisterObserver(Observer: TMiletusObserver);
    procedure UnregisterObserver(Observer: TMiletusObserver);
    procedure ReceiveMessageFromMiletus(const AMessage: string);
    property OnCustomMessage: TMiletusCommunicationCustomMessageEvent read FOnCustomMessage write FOnCustomMessage;
    property OnCustomMessageA: TMiletusCommunicationCustomMessageAEvent read FOnCustomMessageA write FOnCustomMessageA;
  end;

  TMiletusClientCommunicationMessageEvent = procedure (Sender: TObject; AMessageID: Integer; AMessageData: TJSONObject) of object;

  TMiletusClientCommunication = class(TMiletusObserver)
  private
    FOnMessage: TMiletusClientCommunicationMessageEvent;
  protected
    procedure ObserverChange(AMessageID: Integer; AMessageData: TJSONValue); override;
  public
    procedure SendMessage(AMessageID: Integer; AMessageData: TJSONObject);
  published
    property OnMessage: TMiletusClientCommunicationMessageEvent read FOnMessage write FOnMessage;
  end;

  TMiletusOSPlatform = (opWindows, opMacOS, opLinux);
  TMiletusOSArchitecture = (oaX86, oaX64, oaARM32, oaARM64);

  TMiletusOSVersion = record
    Platform: TMiletusOSPlatform;
    Architecture: TMiletusOSArchitecture;
    Name: string;
    ToString: string;
    Build: Integer;
    Major: Integer;
    Minor: Integer;
  end;

  TTMSParams = record
  private class var
    FMiletusParamCount: Integer;
    FMiletusParams: TArray<string>;
    FMiletusParamsCalculated: Boolean;
    FMiletusParamsReceived: TProc;
  public
    class function Execute: TJSPromise; static;
  end;

  TTMSMiletusHelperRec = record
  private class var
    FMiletusStringRes: string;
    FMiletusCursorPos: TPoint;
    FMiletusPath: string;
    FMiletusClipboardHasFormat: Boolean;
    FMiletusClipboardAsText: string;
    FMiletusOSVersion: TMiletusOSVersion;
    FMiletusFiles: TStringDynArray;
    FMiletusDirs: TStringDynArray;
    FMiletusUpdateRes: Boolean;
    FMiletusDLLProcRes: string;
    FMiletusDLLRes: Boolean;
    FMiletusShellExecute: TProc;
    FMiletusCursorPosReceived: TProc;
    FMiletusPathReceived: TProc;
    FMiletusClipboardFormatReceived: TProc;
    FMiletusClipboardTextReceived: TProc;
    FMiletusOSVersionReceived: TProc;
    FMiletusFilesReceived: TProc;
    FMiletusDirsReceived: TProc;
    FMiletusDLLProcExec: TProc;
    FMiletusDLLLoaded: TProc;
    FMiletusUpdateProc: TProc;
  end;

function GetCursorPos: TPoint;
function GetCursorPosP: TJSPromise;
procedure GetMiletusPath(APathType: Integer; var APath: string);
function GetMiletusPathP(APathType: Integer): TJSPromise;
function GetMiletusFilesP(ADirectory: string; AFilter: string = '*'): TJSPromise;
function GetMiletusDirectoriesP(ADirectory: string; AFilter: string = '*'): TJSPromise;
function GetOSVersionP: TJSPromise;
function MiletusClipboard: TMiletusClipboard;
function MiletusShell: TMiletusShell;
function MiletusUpdate: TMiletusUpdate;
procedure MiletusTerminate;
procedure OpenDevTools;
procedure StartFileDrag(APath: string);
function LoadLibrary(ALibraryPath: string): TJSPromise; //Returns boolean (successful or not)
procedure UnloadLibrary(ALibraryPath: string);
function ExecProc(ALibraryPath: string; AProc: string; AData: string): TJSPromise; overload;
function ExecProc(ALibraryPath: string; AProc: string): TJSPromise; overload;
function ExecFunc(ALibraryPath: string; AFunc: string; AData: string): TJSPromise; overload;
function ExecFunc(ALibraryPath: string; AFunc: string): TJSPromise; overload;

var
  MiletusCommunication: TMiletusCommunication;
  MiletusOpenDialogOption: array[TOpenOption] of string = (
    'ofReadOnly', 'ofOverwritePrompt', 'ofHideReadOnly',
    'ofNoChangeDir', 'ofShowHelp', 'ofNoValidate', 'ofAllowMultiSelect',
    'ofExtensionDifferent', 'ofPathMustExist', 'ofFileMustExist', 'ofCreatePrompt',
    'ofShareAware', 'ofNoReadOnlyReturn', 'ofNoTestFileCreate', 'ofNoNetworkButton',
    'ofNoLongNames', 'ofOldStyleDialog', 'ofNoDereferenceLinks', 'ofEnableIncludeNotify',
    'ofEnableSizing', 'ofDontAddToRecent', 'ofForceShowHidden');

const
  {Component messages}
  NM_WINDOW_SHOW = $001;
  NM_WINDOW_CLOSE = $002;
  NM_WINDOW_SHOWMODAL = $003;
  NM_WINDOW_HIDE = $004;
  NM_WINDOW_LOADURL = $005;
  NM_WINDOW_RESIZE = $006;
  NM_WINDOW_MINIMIZE = $007;
  NM_WINDOW_MAXIMIZE = $008;
  NM_WINDOW_MSGREG = $009;
  NM_WINDOW_READY = $010;
  NM_WINDOW_MSGSEND = $00A;
  NM_WINDOW_MSGGET = $00B;
  NM_WINDOW_UPDATE = $00C;
  NM_OPENDEVTOOLS = $00D;
  NM_VERSION_INFO = $00E;
  NM_VERSION_GET = $00F;
  NM_OPENDIALOG_OPEN = $020;
  NM_SAVEDIALOG_OPEN = $021;
  NM_ERRORDLG = $022;
  NM_MESSAGEBOX_OPEN = $023;
  NM_STRINGLIST_LOAD = $024;
  NM_STRINGLIST_SAVE = $025;
  NM_MAINMENU_UPDATE = $026;
  NM_MAINMENU_ITEMCLICK = $027;
  NM_POPUPMENU_OPEN = $028;
  NM_POPUPMENU_CLOSE = $029;
  NM_POPUPMENU_ITEMCLICK = $02A;
  NM_FILEWATCH_ADD = $02B;
  NM_FILEWATCH_REMOVE = $02C;
  NM_FILEWATCH_CHANGE = $02D;
  NM_SHORTCUT_ADD = $02E;
  NM_SHORTCUT_REMOVE = $02F;
  NM_SHORTCUT_CHANGE = $030;
  NM_TRAYICON_UPDATE = $031;
  NM_TRAYICON_CLICK = $032;
  NM_TRAYICON_MENUCLICK = $033;
  NM_DB_CONNECT = $034;
  NM_DB_GETROWS = $035;
  NM_DB_SETROWS = $036;
  NM_DB_GETFIELDS = $037;
  NM_DB_SETFIELDS = $038;
  NM_DB_INSERT = $039;
  NM_DB_MODIFY = $03A;
  NM_DB_DELETE = $03B;
  NM_DB_SETAUTOINC = $03C;
  NM_DB_ERROR = $03D;
  NM_DB_DESTROY = $03E;
  NM_DB_STATECHANGE = $03F;
  NM_INI_READ = $040;
  NM_INI_WRITE = $041;
  NM_INI_DELETE_KEY = $042;
  NM_INI_ERASE_SECTION = $043;
  NM_INI_SECTION_EXISTS = $044;
  NM_INI_VALUE_EXISTS = $045;
  NM_REG_READ = $046;
  NM_REG_WRITE = $047;
  NM_REG_KEY_CREATE = $048;
  NM_REG_KEY_DELETE = $049;
  NM_REG_VALUE_DELETE = $04A;
  NM_REG_KEY_OPEN = $04B;
  NM_REG_KEY_CLOSE = $04C;
  NM_REG_DESTROY = $04D;
  NM_REG_KEY_EXISTS = $04E;
  NM_REG_VALUE_EXISTS = $04F;
  NM_REG_OP_REJECT = $050;
  NM_DB_DRIVER_AFTER_CONNECT = $051;
  NM_DB_DRIVER_AFTER_DISCONNECT = $052;
  NM_DB_DRIVER_BEFORE_CONNECT = $053;
  NM_DB_DRIVER_BEFORE_DISCONNECT = $054;
  NM_DB_EXECSQL = $055;
  NM_DB_DRIVER_ERROR = $056;
  NM_PRINT_BEGIN = $057;
  NM_PRINT_END = $058;
  NM_PRINT_DRAWTEXT = $059;
  NM_PRINT_DRAWLINE = $05A;
  NM_PRINT_DRAWRECT = $05B;
  NM_PRINT_DRAWARC = $05C;
  NM_PRINT_DRAWELLIPSE = $05D;
  NM_PRINT_DRAWIMG = $05E;
  NM_PRINT_DRAWPOLYGON = $05F;
  NM_PRINT_DRAWPOLYLINE = $060;
  NM_PRINT_SETGRAPHICS = $061;
  NM_PRINT_NEWPAGE = $062;
  NM_PRINT_ORIENTATION = $063;
  NM_PRINT_SETDEVICE = $064;
  NM_PRINT_PAGEWIDTH = $065;
  NM_PRINT_PAGEHEIGHT = $066;
  NM_PRINT_GETDEVICES = $067;
  NM_PRINT_GETDEVICE = $068;
  NM_UPDATE_CHECK = $069;
  NM_UPDATE_START = $06A;
  NM_UPDATE_RESTART = $06B;

  {Shell/function messages}
  NM_NOTIFICATION = $100;
  NM_BEEP = $101;
  NM_SHOWFILE = $102;
  NM_OPENPATH = $103;
  NM_OPENURL = $104;
  NM_DELETEFILE = $105;
  NM_STARTDRAG = $106;
  NM_GETCURSOR = $107;
  NM_GETPATH = $108;
  NM_CLIPBOARD_SET = $109;
  NM_CLIPBOARD_GET = $10A;
  NM_CLIPBOARD_FORMAT = $10B;
  NM_APP_TERMINATE = $10C;
  NM_APP_PARAMS = $10D;
  NM_GETOSVERSION = $10E;
  NM_GETFILES = $10F;
  NM_SHELL_EXECUTE = $110;
  NM_DLL_LOAD = $111;
  NM_DLL_FREE = $112;
  NM_DLL_EXEC_PROC = $113;
  NM_DLL_EXEC_FUNC = $114;
  NM_THEME = $115;
  NM_GETDIRECTORIES = $116;

  {Raspberry PI serial communication}
  NM_PI_I2C_OPEN = $350;
  NM_PI_I2C_CLOSE = $351;
  NM_PI_I2C_WRITEBUFFER = $352;
  NM_PI_I2C_READBUFFER = $353;
  NM_PI_I2C_READBYTE = $354;
  NM_PI_I2C_READINT = $355;
  NM_PI_I2C_WRITEBYTE = $356;
  NM_PI_I2C_WRITEADDR = $357;
  NM_PI_SPI_OPEN = $358;
  NM_PI_SPI_CLOSE = $359;
  NM_PI_SPI_COMMAND = $35A;
  NM_PI_SPI_TRANSFER = $35B;
  NM_PI_SPI_READTRANSFER = $35C;
  NM_PI_SPI_WRITETRANSFER = $35D;
  NM_PI_UART_OPEN = $35F;
  NM_PI_UART_CLOSE = $360;
  NM_PI_UART_WRITEBUFFER = $361;
  NM_PI_UART_READBUFFER = $362;
  NM_PI_UART_CANREAD = $363;
  NM_PI_UART_CANWRITE = $364;
  NM_PI_UART_ENABLERTS = $365;
  NM_PI_UART_WAITINGDATA = $366;
  NM_PI_UART_SENDINGDATA = $367;
  NM_PI_UART_MODEMSTATUS = $368;
  NM_PI_UART_RTS = $369;
  NM_PI_UART_CTS = $36A;
  NM_PI_UART_DTR = $36B;
  NM_PI_UART_DSR = $36C;
  NM_PI_GPIO_CONFIG = $36D;
  NM_PI_GPIO_WRITE = $36E;
  NM_PI_GPIO_READ = $36F;
  NM_PI_MEMBUFFER_LOAD = $370;
  NM_PI_MEMBUFFER_SAVE = $371;
  NM_PI_MEMBUFFER_READ = $372;
  NM_PI_MEMBUFFER_WRITE = $373;
  NM_PI_MEMBUFFER_CLEAR = $374;
  NM_PI_SPI_WRITEMEMBUFFER = $375;
  NM_PI_SPI_WRITETRANSFERPIN = $376;

  {User defined}
  NM_USER = $400;

  {Path values}
  NP_APPDATA = $01;
  NP_APPPATH = $02;
  NP_DESKTOP = $03;
  NP_DOCUMENTS = $04;
  NP_DOWNLOADS = $05;
  NP_EXE = $06;
  NP_HOME = $07;
  NP_MUSIC = $08;
  NP_USERDATA = $09;
  NP_PICTURES = $0A;
  NP_TEMP = $0B;
  NP_VIDEOS = $0C;

  {Clipboard formats}
  NC_TEXT = $01;
  NC_BITMAP = $02;
  NC_METAFILEPICT = $03;
  NC_PICTURE = $04;
  NC_COMPONENT = $05;

  {OS version constants}
  OP_WIN = $01;
  OP_MAC = $02;
  OP_LINUX = $03;
  OA_X86 = $04;
  OA_X64 = $05;
  OA_ARM32 = $06;
  OA_ARM64 = $07;

  {INI/REG types}
  IR_BOOL = $01;
  IR_DATE = $02;
  IR_DATETIME = $03;
  IR_FLOAT = $04;
  IR_INT64 = $05;
  IR_INTEGER = $06;
  IR_STRING = $07;
  IR_TIME = $08;
  IR_BINARY_STREAM = $09;
  IR_SECTION = $0A;
  IR_SECTIONS = $0B;
  IR_SECTION_VALUES = $0C;
  IR_SUBSECTIONS = $0D;
  IR_EXPAND_STRING = $0E;
  IR_CURRENCY = $0F;

  MILETUS_CLASSES_ROOT = $00;
  MILETUS_CURRENT_USER = $01;
  MILETUS_LOCAL_MACHINE = $02;
  MILETUS_USERS = $03;
  //MILETUS_PERFORMANCE_DATA = $04;
  MILETUS_CURRENT_CONFIG = $05;

  KEY_ALL_ACCESS = $F003F;
  KEY_READ = $20019;
  KEY_WRITE = $20006;

implementation

uses
  DateUtils, WEBLib.Controls;

procedure SendInternalMessage(AMessage: string);
begin
  asm
    sendTMSMiletusObjectMessageAsync(AMessage);
  end;
end;

procedure SendMessageToMiletus(AMessageID: Integer; AMessageData: TJSONObject);
var
  o: TJSONObject;
  m: string;
begin
  o := TJSONObject.Create;
  o.AddPair('MessageID', AMessageID);
  o.AddPair('MessageData', AMessageData);
  m := encodeURIComponent(o.ToJSON);
  m := TJSString(m).replace(TJSRegexp.new('%([0-9A-F]{2})', 'g'), function (const match, p1: string; offset: Integer; AString: string): string
  begin
    Result := TJSString.fromCharCode('0x' + p1);
  end);
//  m := window.btoa(o.ToJSON);
  SendInternalMessage(window.btoa(m));
  o.Free;
end;

procedure SendInternalMessageSync(AMessage: string);
begin
  asm
    sendTMSMiletusObjectMessage(AMessage);
  end;
end;

procedure SendMessageToMiletusSync(AMessageID: Integer; AMessageData: TJSONObject);
var
  o: TJSONObject;
  m: string;
begin
  o := TJSONObject.Create;
  o.AddPair('MessageID', AMessageID);
  o.AddPair('MessageData', AMessageData);
  m := encodeURIComponent(o.ToJSON);
  m := TJSString(m).replace(TJSRegexp.new('%([0-9A-F]{2})', 'g'), function (const match, p1: string; offset: Integer; AString: string): string
  begin
    Result := TJSString.fromCharCode('0x' + p1);
  end);
//  m := window.btoa(o.ToJSON);
  SendInternalMessageSync(window.btoa(m));
  o.Free;
end;

function GetStringListMessageSync: string;
var
  s: string;
begin
  s := '';
  asm
    if (window.chrome) {
      var obj = window.chrome.webview.hostObjects.sync.TMSMiletus;
      if (obj) {
        s = obj.StringListMessage;
      }
    }
  end;
  Result := s;
end;

function GetDialogMessageSync: string;
var
  s: string;
begin
  s := '';
  asm
    if (window.chrome) {
      var obj = window.chrome.webview.hostObjects.sync.TMSMiletus;
      if (obj) {
        s = obj.DialogMessage;
      }
    }
  end;
  Result := s;
end;

function GetObjectMessageSync: string;
var
  s: string;
begin
  s := '';
  asm
    if (window.chrome) {
      var obj = window.chrome.webview.hostObjects.sync.TMSMiletus;
      if (obj) {
        s = obj.ObjectMessage;
      }
    }
  end;
  Result := s;
end;

function GetCursorPos: TPoint;
var
  o: TJSONObject;
  p: string;
begin
  SendMessageToMiletusSync(NM_GETCURSOR, TJSONObject.Create);
  p := GetObjectMessageSync;
  if p <> '' then
  begin
    o := TJSONObject(TJSONObject.ParseJSONValue(p));
    Result := Point(StrToInt(o.GetJSONValue('X')), StrToInt(o.GetJSONValue('Y')));
  end
  else
    Result := Point(0, 0);
end;

function GetCursorPosP: TJSPromise;
begin
  Result := TJSPromise.New(
  procedure(AResolve, AReject: TJSPromiseResolver)
	begin
	  TTMSMiletusHelperRec.FMiletusCursorPosReceived :=
	  procedure()
	  begin
	    AResolve(TTMSMiletusHelperRec.FMiletusCursorPos);
	  end;
	  SendMessageToMiletus(NM_GETCURSOR, TJSONObject.Create);
	end);
end;

procedure GetMiletusPath(APathType: Integer; var APath: string);
var
  o: TJSONObject;
  p: string;
begin
  o := TJSONObject.Create;
  o.AddPair('PathType', APathType);
  SendMessageToMiletusSync(NM_GETPATH, o);
  p := GetObjectMessageSync;
  if p <> '' then
  begin
    o := TJSONObject(TJSONObject.ParseJSONValue(p));
    APath := o.GetJSONValue('Path');
  end
  else
    APath := '';
end;

function GetMiletusPathP(APathType: Integer): TJSPromise;
var
  o: TJSONObject;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    begin
      TTMSMiletusHelperRec.FMiletusPathReceived :=
      procedure()
      begin
        AResolve(TTMSMiletusHelperRec.FMiletusPath);
      end;
      o := TJSONObject.Create;
      o.AddPair('PathType', APathType);
      SendMessageToMiletus(NM_GETPATH, o);
    end);
end;

function GetMiletusFilesP(ADirectory: string; AFilter: string): TJSPromise;
var
  o: TJSONObject;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    begin
      TTMSMiletusHelperRec.FMiletusFilesReceived :=
      procedure()
      begin
        AResolve(TTMSMiletusHelperRec.FMiletusFiles);
      end;
      o := TJSONObject.Create;
      o.AddPair('Directory', ADirectory);
      o.AddPair('Filter', AFilter);
      SendMessageToMiletus(NM_GETFILES, o);
    end);
end;

function GetMiletusDirectoriesP(ADirectory: string; AFilter: string): TJSPromise;
var
  o: TJSONObject;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    begin
      TTMSMiletusHelperRec.FMiletusDirsReceived :=
      procedure()
      begin
        AResolve(TTMSMiletusHelperRec.FMiletusDirs);
      end;
      o := TJSONObject.Create;
      o.AddPair('Directory', ADirectory);
      o.AddPair('Filter', AFilter);
      SendMessageToMiletus(NM_GETDIRECTORIES, o);
    end);
end;

function GetOSVersionP: TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    begin
      TTMSMiletusHelperRec.FMiletusOSVersionReceived :=
      procedure()
      begin
        AResolve(TTMSMiletusHelperRec.FMiletusOSVersion);
      end;
      SendMessageToMiletus(NM_GETOSVERSION, TJSONObject.Create);
    end);
end;

procedure StartFileDrag(APath: string);
var
  o: TJSONObject;
begin
  o := TJSONObject.Create;
  o.AddPair('Path', APath);
  SendMessageToMiletus(NM_STARTDRAG, o);
end;

procedure OpenDevTools;
var
  o: TJSONObject;
begin
  o := TJSONObject.Create;
  SendMessageToMiletus(NM_OPENDEVTOOLS, o);
end;

procedure MiletusTerminate;
begin
  SendMessageToMiletus(NM_APP_TERMINATE, TJSONObject.Create);
end;

function LoadLibrary(ALibraryPath: string): TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    var
      o: TJSONObject;
    begin
      TTMSMiletusHelperRec.FMiletusDLLLoaded :=
      procedure()
      begin
        AResolve(TTMSMiletusHelperRec.FMiletusDLLRes);
      end;
      o := TJSONObject.Create;
      o.AddPair('Name', ALibraryPath);
      SendMessageToMiletus(NM_DLL_LOAD, o);
    end);
end;

procedure UnloadLibrary(ALibraryPath: string);
var
  o: TJSONObject;
begin
  o := TJSONObject.Create;
  o.AddPair('Name', ALibraryPath);
  SendMessageToMiletus(NM_DLL_FREE, o);
end;

function ExecProc(ALibraryPath: string; AProc: string; AData: string): TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    var
      o: TJSONObject;
    begin
      TTMSMiletusHelperRec.FMiletusDLLProcExec :=
      procedure()
      begin
        AResolve(nil);
      end;
      o := TJSONObject.Create;
      o.AddPair('Name', ALibraryPath);
      o.AddPair('Proc', AProc);
      o.AddPair('JSON', AData);
      SendMessageToMiletus(NM_DLL_EXEC_PROC, o);
    end);
end;

function ExecProc(ALibraryPath: string; AProc: string): TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    var
      o: TJSONObject;
    begin
      TTMSMiletusHelperRec.FMiletusDLLProcExec :=
      procedure()
      begin
        AResolve(nil);
      end;
      o := TJSONObject.Create;
      o.AddPair('Name', ALibraryPath);
      o.AddPair('Proc', AProc);
      SendMessageToMiletus(NM_DLL_EXEC_PROC, o);
    end);
end;

function ExecFunc(ALibraryPath: string; AFunc: string; AData: string): TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    var
      o: TJSONObject;
    begin
      TTMSMiletusHelperRec.FMiletusDLLProcExec :=
      procedure()
      begin
        AResolve(TTMSMiletusHelperRec.FMiletusDLLProcRes);
      end;
      o := TJSONObject.Create;
      o.AddPair('Name', ALibraryPath);
      o.AddPair('Func', AFunc);
      o.AddPair('JSON', AData);
      SendMessageToMiletus(NM_DLL_EXEC_FUNC, o);
    end);
end;

function ExecFunc(ALibraryPath: string; AFunc: string): TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    var
      o: TJSONObject;
    begin
      TTMSMiletusHelperRec.FMiletusDLLProcExec :=
      procedure()
      begin
        AResolve(TTMSMiletusHelperRec.FMiletusDLLProcRes);
      end;
      o := TJSONObject.Create;
      o.AddPair('Name', ALibraryPath);
      o.AddPair('Func', AFunc);
      SendMessageToMiletus(NM_DLL_EXEC_FUNC, o);
    end);
end;

{ TMiletusStringList }

constructor TMiletusStringList.Create;
var
  g: TGUID;
begin
  inherited Create;
  CreateGUID(g);
  FGUID := GUIDToString(g);
  FObserver := TMiletusObserver.Create(nil);
  FObserver.OnObserverChange := DoStringListResponse;
end;

destructor TMiletusStringList.Destroy;
begin
  FObserver.Free;
  inherited;
end;

procedure TMiletusStringList.DoStringListResponse(Sender: TObject; AMessageID: Integer; AData: TJSONValue);
var
  s: string;
begin
  if AMessageID = NM_STRINGLIST_LOAD then
  begin
    s := TJSONObject(AData).GetJSONValue('GUID');
    if (s = '') or (FGUID <> s) then
      Exit;

    if not Assigned(TJSONObject(AData).Get('Text')) then
      raise Exception.Create('File does not exists.');

    Text := TJSONObject(AData).GetJSONValue('Text');
    if Assigned(FLoadProc) then
      FLoadProc;
    FLoadProc := nil;
  end;
end;

procedure TMiletusStringList.LoadFromFile(const FileName: string);
var
  o: TJSONObject;
  res: string;
begin
  o := TJSONObject.Create;
  o.AddPair('IsAsync', TJSONValue.Create(False));
  o.AddPair('IsString', TJSONValue.Create(True));
  o.AddPair('GUID', FGUID);
  o.AddPair('FileName', FileName);
  SendMessageToMiletusSync(NM_STRINGLIST_LOAD, o);
  res := GetStringListMessageSync;
  if res <> '' then
  begin
    o := TJSONObject(TJSONObject.ParseJSONValue(res));
    Text := o.GetJSONValue('Text');
    Text := StringReplace(Text, #13#10, #10, [rfReplaceAll, rfIgnoreCase]);
    Text := StringReplace(Text, #13, #10, [rfReplaceAll, rfIgnoreCase]);
  end;
  o.Free;
end;

procedure TMiletusStringList.InternalSendToFile(const FileName: string;
  ASync: Boolean);
var
  o: TJSONObject;
begin
  o := TJSONObject.Create;
  o.AddPair('FileName', FileName);
  o.AddPair('Text', Text);
  if ASync then
    SendMessageToMiletus(NM_STRINGLIST_SAVE, o)
  else
    SendMessageToMiletusSync(NM_STRINGLIST_SAVE, o);
  o.Free;
end;

procedure TMiletusStringList.LoadFromFileAsync(const FileName: string;
  AProc: TMiletusLoadFileProc);
var
  o: TJSONObject;
begin
  FLoadProc := AProc;
  o := TJSONObject.Create;
  o.AddPair('IsAsync', TJSONValue.Create(True));
  o.AddPair('IsString', TJSONValue.Create(True));
  o.AddPair('GUID', FGUID);
  o.AddPair('FileName', FileName);
  SendMessageToMiletus(NM_STRINGLIST_LOAD, o);
  o.Free;
end;

procedure TMiletusStringList.SaveToFile(const FileName: string);
begin
  InternalSendToFile(FileName, False);
end;

procedure TMiletusStringList.SaveToFileAsync(const FileName: string);
begin
  InternalSendToFile(FileName, True);
end;

{ TMiletusCommunication }

procedure TMiletusCommunication.Change(AMessageID: Integer; AMessageData: TJSONValue);
var
  Obs: TMiletusObserver;
  I: Integer;
begin
  for I := FObservers.Count - 1 downto 0 do
  begin
    Obs := TMiletusObserver(FObservers[I]);
    if Assigned(Obs) then
    begin
      if Obs.ObserverEnabled then
        Obs.ObserverChange(AMessageID, AMessageData);
    end
    else
      FObservers.Delete(I);
  end;
end;

constructor TMiletusCommunication.Create;
begin
  FObservers := TList.Create;
end;

destructor TMiletusCommunication.Destroy;
begin
  FObservers.Free;
  inherited;
end;

procedure TMiletusCommunication.DoHandleAppParams(AMessageData: TJSONValue);
var
  o: TJSONObject;
  arr: TJSONArray;
  I: Integer;
begin
  o := TJSONObject(AMessageData);
  TTMSParams.FMiletusParamCount := StrToInt(o.GetJSONValue('ParamCount'));
  arr := TJSONArray(o.GetValue('ParamStr'));
  SetLength(TTMSParams.FMiletusParams, TTMSParams.FMiletusParamCount);
  for I := 0 to arr.Count - 1 do
    TTMSParams.FMiletusParams[I] := arr.Items[I].Value;
  if Assigned(TTMSParams.FMiletusParamsReceived) then
    TTMSParams.FMiletusParamsReceived;
  TTMSParams.FMiletusParamsCalculated := True;
  o.Free;
end;

procedure TMiletusCommunication.DoHandleClipboardFormat(
  AMessageData: TJSONValue);
var
  o: TJSONObject;
begin
  o := TJSONObject(AMessageData);
  TTMSMiletusHelperRec.FMiletusClipboardHasFormat := False;
  if StrToBool(o.GetJSONValue('HasFormat')) then
    TTMSMiletusHelperRec.FMiletusClipboardHasFormat := True;
  if Assigned(TTMSMiletusHelperRec.FMiletusClipboardFormatReceived) then
    TTMSMiletusHelperRec.FMiletusClipboardFormatReceived;
  o.Free;
end;

procedure TMiletusCommunication.DoHandleClipboardText(AMessageData: TJSONValue);
var
  o: TJSONObject;
begin
  o := TJSONObject(AMessageData);
  TTMSMiletusHelperRec.FMiletusClipboardAsText := o.GetJSONValue('Text');
  if Assigned(TTMSMiletusHelperRec.FMiletusClipboardTextReceived) then
    TTMSMiletusHelperRec.FMiletusClipboardTextReceived;
  o.Free;
end;

procedure TMiletusCommunication.DoHandleCursorPos(AMessageData: TJSONValue);
var
  o: TJSONObject;
begin
  o := TJSONObject(AMessageData);
  TTMSMiletusHelperRec.FMiletusCursorPos := Point(StrToInt(o.GetJSONValue('X')), StrToInt(o.GetJSONValue('Y')));
  if Assigned(TTMSMiletusHelperRec.FMiletusCursorPosReceived) then
    TTMSMiletusHelperRec.FMiletusCursorPosReceived;
  o.Free;
end;

procedure TMiletusCommunication.DoHandleCustomDLLMsg(AMessageData: TJSONValue);
var
  o: TJSONObject;
begin
  o := TJSONObject(AMessageData);

  if Assigned(OnCustomMessageA) then
    OnCustomMessageA(StrToInt(o.GetJSONValue('ID')), o.GetJSONValue('Data'));

  if Assigned(OnCustomMessage) then
    OnCustomMessage(StrToInt(o.GetJSONValue('ID')), o.GetJSONValue('Data'));

  o.Free;
end;

procedure TMiletusCommunication.DoHandleDLLFunc(AMessageData: TJSONValue);
var
  o: TJSONObject;
begin
  o := TJSONObject(AMessageData);
  TTMSMiletusHelperRec.FMiletusDLLProcRes := o.GetJSONValue('Result');
  if Assigned(TTMSMiletusHelperRec.FMiletusDLLProcExec) then
    TTMSMiletusHelperRec.FMiletusDLLProcExec;
  o.Free;
end;

procedure TMiletusCommunication.DoHandleDLLLoad(AMessageData: TJSONValue);
var
  o: TJSONObject;
begin
  o := TJSONObject(AMessageData);
  TTMSMiletusHelperRec.FMiletusDLLRes := StrToInt(o.GetJSONValue('Result')) <> 0;
  if Assigned(TTMSMiletusHelperRec.FMiletusDLLLoaded) then
    TTMSMiletusHelperRec.FMiletusDLLLoaded;
  o.Free;
end;

procedure TMiletusCommunication.DoHandleDLLProc(AMessageData: TJSONValue);
begin
  if Assigned(TTMSMiletusHelperRec.FMiletusDLLProcExec) then
    TTMSMiletusHelperRec.FMiletusDLLProcExec;
end;

procedure TMiletusCommunication.DoHandleFileList(AMessageData: TJSONValue);
var
  arr: TJSONArray;
  o: TJSONObject;
  I: Integer;
begin
  o := TJSONObject(AMessageData);
  arr := TJSONArray(o.GetValue('Files'));
  SetLength(TTMSMiletusHelperRec.FMiletusFiles, arr.Count);
  for I := 0 to arr.Count - 1 do
    TTMSMiletusHelperRec.FMiletusFiles[I] := arr.Items[I].Value;
  if Assigned(TTMSMiletusHelperRec.FMiletusFilesReceived) then
    TTMSMiletusHelperRec.FMiletusFilesReceived;
  o.Free;
  arr.Free;
end;

procedure TMiletusCommunication.DoHandleDirList(AMessageData: TJSONValue);
var
  arr: TJSONArray;
  o: TJSONObject;
  I: Integer;
begin
  o := TJSONObject(AMessageData);
  arr := TJSONArray(o.GetValue('Directories'));
  SetLength(TTMSMiletusHelperRec.FMiletusDirs, arr.Count);
  for I := 0 to arr.Count - 1 do
    TTMSMiletusHelperRec.FMiletusDirs[I] := arr.Items[I].Value;
  if Assigned(TTMSMiletusHelperRec.FMiletusDirsReceived) then
    TTMSMiletusHelperRec.FMiletusDirsReceived;
  o.Free;
  arr.Free;
end;

procedure TMiletusCommunication.DoHandleOSVersion(AMessageData: TJSONValue);
var
  o: TJSONObject;
  ov: TMiletusOSVersion;
  i: Integer;
begin
  o := TJSONObject(AMessageData);
  i := StrToInt(o.GetJSONValue('Platform'));
  case i of
    OP_WIN: ov.Platform := opWindows;
    OP_MAC: ov.Platform := opMacOS;
    OP_LINUX: ov.Platform := opLinux;
  end;
  i := StrtoInt(o.GetJSONValue('Architecture'));
  case i of
    OA_X86: ov.Architecture := oaX86;
    OA_X64: ov.Architecture := oaX64;
    OA_ARM32: ov.Architecture := oaARM32;
    OA_ARM64: ov.Architecture := oaARM64;
  end;
  ov.Name := o.GetJSONValue('Name');
  ov.ToString := o.GetJSONValue('ToString');
  ov.Build := StrToInt(o.GetJSONValue('Build'));
  ov.Major := StrToInt(o.GetJSONValue('Major'));
  ov.Minor := StrToInt(o.GetJSONValue('Minor'));
  TTMSMiletusHelperRec.FMiletusOSVersion := ov;
  if Assigned(TTMSMiletusHelperRec.FMiletusOSVersionReceived) then
    TTMSMiletusHelperRec.FMiletusOSVersionReceived;
  o.Free;
end;

procedure TMiletusCommunication.DoHandlePaths(AMessageData: TJSONValue);
var
  o: TJSONObject;
begin
  o := TJSONObject(AMessageData);
  TTMSMiletusHelperRec.FMiletusPath := o.GetJSONValue('Path');
  if Assigned(TTMSMiletusHelperRec.FMiletusPathReceived) then
    TTMSMiletusHelperRec.FMiletusPathReceived;
  o.Free;
end;

procedure TMiletusCommunication.DoHandleShellExecute(AMessageData: TJSONValue);
var
  o: TJSONObject;
begin
  o := TJSONObject(AMessageData);
  TTMSMiletusHelperRec.FMiletusStringRes := o.GetJSONValue('Result');
  if Assigned(TTMSMiletusHelperRec.FMiletusShellExecute) then
    TTMSMiletusHelperRec.FMiletusShellExecute;
  o.Free;
end;

procedure TMiletusCommunication.DoHandleUpdateCheck(AMessageData: TJSONValue);
var
  o: TJSONObject;
begin
  o := TJSONObject(AMessageData);
  TTMSMiletusHelperRec.FMiletusUpdateRes := StrToBool(o.GetJSONValue('Result'));
  if Assigned(TTMSMiletusHelperRec.FMiletusUpdateProc) then
    TTMSMiletusHelperRec.FMiletusUpdateProc;
end;

procedure TMiletusCommunication.DoHandleUpdateStart;
begin
  if Assigned(TTMSMiletusHelperRec.FMiletusUpdateProc) then
    TTMSMiletusHelperRec.FMiletusUpdateProc;
end;

procedure TMiletusCommunication.DoHandleVersionInfo(AMessageData: TJSONValue);
var
  o: TJSONObject;
begin
  o := TJSONObject.Create;
  o.AddPair('WebCoreVersion', Application.Version);
  o.AddPair('BrowserVersion', window.navigator.userAgent);
  SendMessageToMiletus(NM_VERSION_INFO, o);
end;

procedure TMiletusCommunication.ReceiveMessageFromMiletus(const AMessage: string);
var
  m: string;
  o: TJSONValue;
  msgid: Integer;
begin
  m := '';
  asm
    function decodeUnicode(str) {
      return decodeURIComponent(atob(str).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
    }
    m = decodeUnicode(AMessage);
  end;
//  m := window.atob(AMessage);
  if m <> '' then
    o := TJSONObject.ParseJSONValue(m)
  else
    Exit;

  if Assigned(o) then
  begin
    try
      msgid := StrToInt(TJSONObject(o).GetJSONValue('MessageID'));
      o := TJSONObject(o).GetValue('MessageData');
      Change(msgid, o);
      case msgid of
        NM_VERSION_GET: DoHandleVersionInfo(o);
        NM_APP_PARAMS: DoHandleAppParams(o);
        NM_GETCURSOR: DoHandleCursorPos(o);
        NM_GETPATH: DoHandlePaths(o);
        NM_GETFILES: DoHandleFileList(o);
        NM_GETDIRECTORIES: DoHandleDirList(o);
        NM_CLIPBOARD_FORMAT: DoHandleClipboardFormat(o);
        NM_CLIPBOARD_GET: DoHandleClipboardText(o);
        NM_GETOSVERSION: DoHandleOSVersion(o);
        NM_SHELL_EXECUTE: DoHandleShellExecute(o);
        NM_DLL_LOAD: DoHandleDLLLoad(o);
        NM_DLL_EXEC_PROC: DoHandleDLLProc(o);
        NM_DLL_EXEC_FUNC: DoHandleDLLFunc(o);
        NM_USER: DoHandleCustomDLLMsg(o);
        NM_UPDATE_START: DoHandleUpdateStart;
        NM_UPDATE_CHECK: DoHandleUpdateCheck(o);
      end;
    finally
      o.Free;
    end;
  end;
end;

procedure TMiletusCommunication.RegisterObserver(Observer: TMiletusObserver);
begin
  if FObservers.IndexOf(Observer) = -1 then
    FObservers.Add(Observer);
end;

procedure TMiletusCommunication.UnregisterObserver(Observer: TMiletusObserver);
var
  i: Integer;
begin
  i := FObservers.IndexOf(Observer);
  FObservers[i] := nil;
  //FObservers.Remove(Observer);
end;

{ TMiletusOpenDialog }

constructor TMiletusOpenDialog.Create(AOwner: TComponent);
var
  g: TGUID;
begin
  inherited;
  FOpenID := NM_OPENDIALOG_OPEN;
  FFiles := TStringList.Create;
  FDefaultExt := '';
  FFileName := '';
  FFilter := '';
  FFilterIndex := 1;
  FInitialDir := '';
  FTitle := '';
  FOptions := [];
  CreateGUID(g);
  FGUID := GUIDToString(g);
end;

destructor TMiletusOpenDialog.Destroy;
begin
  FFiles.Free;
  inherited;
end;

function TMiletusOpenDialog.Execute(ASync: Boolean): Boolean;
var
  js: TJSON;
  o: TJSONObject;
  v: TJSONValue;
  a: TJSONArray;
  opt: TOpenOption;
  I: Integer;
  res: string;
begin
  Result := False;
  if Async then
    Execute(nil)
  else
  begin
    a := TJSONArray.Create;
    for opt := Low(TOpenOptions) to High(TOpenOptions) do
    begin
      if opt in Options then
        a.Add(MiletusOpenDialogOption[opt]);
    end;

    o := TJSONObject.Create;
    o.AddPair('IsAsync', TJSONValue.Create(False));
    o.AddPair('GUID', FGUID);
    o.AddPair('FileName', FileName);
    o.AddPair('DefaultExt', DefaultExt);
    o.AddPair('Filter', Filter);
    o.AddPair('InitialDir', FInitialDir);
    o.AddPair('Title', Title);
    o.AddPair('FilterIndex', FilterIndex);
    o.AddPair('Options', a);
    SendMessageToMiletusSync(FOpenID, o);

    res := GetDialogMessageSync;
    if res <> '' then
    begin
      js := TJSON.Create;
      v := js.Parse(res);
      if v is TJSONObject then
      begin
        o := v as TJSONObject;
        if StrToBool(o.GetJSONValue('Execute')) then
        begin
          FileName := o.GetJSONValue('FileName');
          a := TJSONArray(o.GetValue('Files'));
          Files.Clear;
          for I := 0 to a.Count - 1 do
            Files.Add(a.Items[I].Value);

          Result := True;
        end
        else
          Result := False;
      end;
    end;
  end;
end;

procedure TMiletusOpenDialog.ObserverChange(AMessageID: Integer;
  AMessageData: TJSONValue);
var
  s: string;
  a: TJSONArray;
  I: Integer;
begin
  inherited;
  if (AMessageID = NM_OPENDIALOG_OPEN) or (AMessageID = NM_SAVEDIALOG_OPEN) then
  begin
    s := TJSONObject(AMessageData).GetJSONValue('GUID');
    if (s = '') or (s <> FGUID) then
      Exit;

    s := TJSONObject(AMessageData).GetJSONValue('Execute');
    if StrToBool(s) then
    begin
      FileName := TJSONObject(AMessageData).GetJSONValue('FileName');
      a := TJSONArray(TJSONObject(AMessageData).GetValue('Files'));
      Files.Clear;
      for I := 0 to a.Count - 1 do
        Files.Add(a.Items[I].Value);

      if Assigned(FResponseProc) then
        FResponseProc(FileName);

      if Assigned(OnExecute) then
        OnExecute(Self, FileName);
    end;

    FResponseProc := nil;
  end;
end;

procedure TMiletusOpenDialog.SetOptions(const Value: TOpenOptions);
begin
  FOptions := Value;
end;

procedure TMiletusOpenDialog.Execute(AProc: TMiletusDialogProc);
var
  o: TJSONObject;
  a: TJSONArray;
  opt: TOpenOption;
begin
  FResponseProc := AProc;
  a := TJSONArray.Create;
  for opt := Low(TOpenOptions) to High(TOpenOptions) do
  begin
    if opt in Options then
      a.Add(MiletusOpenDialogOption[opt]);
  end;

  o := TJSONObject.Create;
  o.AddPair('IsAsync', TJSONValue.Create(True));
  o.AddPair('GUID', FGUID);
  o.AddPair('FileName', FileName);
  o.AddPair('DefaultExt', DefaultExt);
  o.AddPair('InitialDir', FInitialDir);
  o.AddPair('Filter', Filter);
  o.AddPair('Title', Title);
  o.AddPair('FilterIndex', FilterIndex);
  o.AddPair('Options', a);
  SendMessageToMiletus(FOpenID, o);
  //o.Free;
end;

procedure InitJS;
var
  scrObj: TJSElement;
begin
  scrObj := document.getElementById('TMSMiletusScript');
  if not Assigned(scrObj) then
  begin
    scrObj := document.createElement('script');
    scrObj.id := 'TMSMiletusScript';
    scrObj.innerHTML :=
      'var sendTMSMiletusObjectMessage = function(parameters) {' + #13 +
      '  var v = parameters;' + #13 +
      '  if (window.chrome) {' + #13 +
      '    if (window.chrome.webview) {' + #13 +
      '      var obj = window.chrome.webview.hostObjects.sync.TMSMiletus;' + #13 +
      '      if (obj) {' + #13 +
      '        obj.ObjectMessage = v;' + #13 +
      '      }' + #13 +
      '    }' + #13 +
      '  } else if (window.webkit) {' + #13 +
      '    window.webkit.messageHandlers.TMSMiletus.postMessage(v);' + #13 +
      '  }' + #13 +
      '};' + #13 + #13 +
      'var sendTMSMiletusObjectMessageAsync = function(parameters) {' + #13 +
      '  var v = parameters;' + #13 +
      '  if (window.chrome) {' + #13 +
      '    if (window.chrome.webview) {' + #13 +
      '      var obj = window.chrome.webview.hostObjects.TMSMiletus;' + #13 +
      '      if (obj) {' + #13 +
      '        obj.ObjectMessage = v;' + #13 +
      '      }' + #13 +
      '    }' + #13 +
      '  } else if (window.webkit) {' + #13 +
      '    window.webkit.messageHandlers.TMSMiletus.postMessage(v);' + #13 +
      '  }' + #13 +
      '};';
    document.head.appendChild(scrObj);
  end;
end;

{ TMiletusMainMenu }

constructor TMiletusMainMenu.Create(AOwner: TComponent);
var
  g: TGUID;
begin
  inherited;
  FObserver := TMiletusObserver.Create(Self);
  FObserver.OnObserverChange := DoReceiveMenuResponse;
  CreateGUID(g);
  FGUID := GUIDToString(g);
end;

function TMiletusMainMenu.CreateElement: TJSElement;
var
  LLabel: TJSHTMLElement;
begin
  if (csDesigning in ComponentState) then
  begin
    Result := document.createElement('DIV');
    LLabel := TJSHTMLElement(document.createElement('DIV'));
    LLabel.innerHTML := 'TMiletusMainMenu';
    LLabel['align'] := 'center';
    LLabel.style.setProperty('border','1px solid gray');
    LLabel.style.setProperty('vertical-align','middle');
    LLabel.style.setProperty('display','table-cell');
    Result.appendChild(LLabel);
  end
end;

destructor TMiletusMainMenu.Destroy;
begin
  FObserver.Free;
  inherited;
end;

procedure TMiletusMainMenu.DoReceiveMenuResponse(Sender: TObject; AMessageID: Integer; AData: TJSONValue);
var
  mis: string;
begin
  if AMessageID = NM_MAINMENU_ITEMCLICK then
  begin
    mis := TJSONObject(AData).GetJSONValue('MenuItemIndex');
    MenuItemClick(mis, StrToBool(TJSONObject(AData).GetJSONValue('Checked')));
  end;
end;

procedure TMiletusMainMenu.EndUpdate;
begin
  inherited;
  GenerateMainMenu;
end;

procedure TMiletusMainMenu.GenerateMainMenu;
  function Traverse(AMenuItem: TMenuItem): TJSONObject;
  var
    itObj: TJSONObject;
    itArr: TJSONArray;
    I: Integer;
  begin
    Result := TJSONObject.Create;
    itArr := TJSONArray.Create;
    if Assigned(AMenuItem.Items) then
    begin
      for I := 0 to AMenuItem.Items.Count - 1 do
      begin
        itObj := Traverse(AMenuItem.Items[I]);
        itArr.Add(itObj);
      end;
    end;

    Result.AddPair('AutoCheck', TJSONValue.Create(AMenuItem.AutoCheck));
    Result.AddPair('Caption', AMenuItem.Caption);
    Result.AddPair('Checked', TJSONValue.Create(AMenuItem.Checked));
    Result.AddPair('Default', TJSONValue.Create(AMenuItem.Default));
    Result.AddPair('Enabled', TJSONValue.Create(AMenuItem.Enabled));
    Result.AddPair('GroupIndex', AMenuItem.GroupIndex);
    Result.AddPair('Hint', AMenuItem.Hint);
    Result.AddPair('ImageIndex', AMenuItem.ImageIndex);
    Result.AddPair('Items', itArr);
    Result.AddPair('RadioItem', TJSONValue.Create(AMenuItem.RadioItem));
    Result.AddPair('ShortCut', AMenuItem.ShortCut);
    Result.AddPair('Visible', TJSONValue.Create(AMenuItem.Visible));
  end;
var
  o, itmObj: TJSONObject;
  itmArr: TJSONArray;
  I: Integer;
begin
  o := TJSONObject.Create;
  o.AddPair('GUID', FGuid);
  o.AddPair('Name', Name);

  if not Assigned(Items) then
    Exit;

  itmArr := TJSONArray.Create;
  for I := 0 to Items.Count - 1 do
  begin
    itmObj := Traverse(Items.Items[I]);
    itmArr.Add(itmObj);
  end;
  o.AddPair('Items', itmArr);
  SendMessageToMiletus(NM_MAINMENU_UPDATE, o);
end;

procedure TMiletusMainMenu.MenuItemClick(AIndexes: string; AChecked: Boolean);
var
  mi: TMenuItem;
  p: Integer;
  i: Integer;
begin
  p := Pos('-', AIndexes);
  while p > 0 do
  begin
    i := StrToInt(Copy(AIndexes, 1, 1));
    if not Assigned(mi) then
      mi := Items.Items[i]
    else
      mi := mi.Items.Items[i];

    Delete(AIndexes, 1, p);
    p := Pos('-', AIndexes);
  end;
  i := StrToInt(Copy(AIndexes, 1, 1));
  if not Assigned(mi) then
    mi := Items.Items[i]
  else
    mi := mi.Items.Items[i];

  mi.Checked := AChecked;

  if Assigned(mi.OnClick) then
    mi.OnClick(mi);
end;

{ TMiletusClientCommunication }

procedure TMiletusClientCommunication.ObserverChange(AMessageID: Integer;
  AMessageData: TJSONValue);
begin
  inherited;
  if Assigned(OnMessage) then
    OnMessage(Self, AMessageID, TJSONObject(AMessageData));
end;

procedure TMiletusClientCommunication.SendMessage(AMessageID: Integer;
  AMessageData: TJSONObject);
begin
  SendMessageToMiletus(AMessageID, AMessageData);
end;

{ TMiletusSaveDialog }

constructor TMiletusSaveDialog.Create(AOwner: TComponent);
begin
  inherited;
  FOpenID := NM_SAVEDIALOG_OPEN;
end;

{ TMiletusPopupMenu }

procedure TMiletusPopupMenu.CloseMenu;
begin
  SendMessageToMiletus(NM_POPUPMENU_CLOSE, TJSONObject.Create);
end;

constructor TMiletusPopupMenu.Create(AOwner: TComponent);
var
  g: TGUID;
begin
  inherited;
  FObserver := TMiletusObserver.Create(Self);
  FObserver.OnObserverChange := DoReceivePopupMenuResponse;
  CreateGUID(g);
  FGUID := GUIDToString(g);
end;

destructor TMiletusPopupMenu.Destroy;
begin
  FObserver.Free;
  inherited;
end;

procedure TMiletusPopupMenu.DoReceivePopupMenuResponse(Sender: TObject; AMessageID: Integer;
  AData: TJSONValue);
var
  s: string;
begin
  s := TJSONObject(AData).GetJSONValue('GUID');
  if (s = '') or (FGUID <> s) then
    Exit;


  if AMessageID = NM_POPUPMENU_OPEN then
  begin
    if Assigned(OnPopup) then
      OnPopup(Self);
  end
  else if AMessageID = NM_POPUPMENU_ITEMCLICK then
  begin
    s := TJSONObject(AData).GetJSONValue('MenuItemIndex');
    MenuItemClick(s, StrToBool(TJSONObject(AData).GetJSONValue('Checked')));
  end;
end;

function TMiletusPopupMenu.GeneratePopupMenu(AX, AY: Integer): TJSONObject;
  function Traverse(AMenuItem: TMenuItem): TJSONObject;
  var
    itObj: TJSONObject;
    itArr: TJSONArray;
    I: Integer;
  begin
    Result := TJSONObject.Create;
    itArr := TJSONArray.Create;
    if Assigned(AMenuItem.Items) then
    begin
      for I := 0 to AMenuItem.Items.Count - 1 do
      begin
        itObj := Traverse(AMenuItem.Items[I]);
        itArr.Add(itObj);
      end;
    end;

    Result.AddPair('AutoCheck', TJSONValue.Create(AMenuItem.AutoCheck));
    Result.AddPair('Caption', AMenuItem.Caption);
    Result.AddPair('Checked', TJSONValue.Create(AMenuItem.Checked));
    Result.AddPair('Default', TJSONValue.Create(AMenuItem.Default));
    Result.AddPair('Enabled', TJSONValue.Create(AMenuItem.Enabled));
    Result.AddPair('GroupIndex', AMenuItem.GroupIndex);
    Result.AddPair('Hint', AMenuItem.Hint);
    Result.AddPair('ImageIndex', AMenuItem.ImageIndex);
    Result.AddPair('Items', itArr);
    Result.AddPair('RadioItem', TJSONValue.Create(AMenuItem.RadioItem));
    Result.AddPair('ShortCut', AMenuItem.ShortCut);
    Result.AddPair('Visible', TJSONValue.Create(AMenuItem.Visible));
  end;
var
  itmObj: TJSONObject;
  itmArr: TJSONArray;
  I: Integer;
begin
  Result := TJSONObject.Create;

  if not Assigned(Items) then
    Exit;

  Result.AddPair('GUID', FGUID);
  Result.AddPair('Name', Name);
  Result.AddPair('X', AX);
  Result.AddPair('Y', AY);

  itmArr := TJSONArray.Create;
  for I := 0 to Items.Count - 1 do
  begin
    itmObj := Traverse(Items.Items[I]);
    itmArr.Add(itmObj);
  end;
  Result.AddPair('Items', itmArr);
end;

procedure TMiletusPopupMenu.MenuItemClick(AIndexes: string; AChecked: Boolean);
var
  mi: TMenuItem;
  p: Integer;
  i: Integer;
begin
  p := Pos('-', AIndexes);
  while p > 0 do
  begin
    i := StrToInt(Copy(AIndexes, 1, 1));
    if not Assigned(mi) then
      mi := Items.Items[i]
    else
      mi := mi.Items.Items[i];

    Delete(AIndexes, 1, p);
    p := Pos('-', AIndexes);
  end;
  i := StrToInt(Copy(AIndexes, 1, 1));
  if not Assigned(mi) then
    mi := Items.Items[i]
  else
    mi := mi.Items.Items[i];

  mi.Checked := AChecked;

  if Assigned(mi.OnClick) then
    mi.OnClick(mi);
end;

procedure TMiletusPopupMenu.Popup(X, Y: Integer);
var
  o: TJSONObject;
begin
  o := GeneratePopupMenu(X, Y);
  SendMessageToMiletus(NM_POPUPMENU_OPEN, o);
end;

{ TMiletusNotificationCenter }

function TMiletusNotificationCenter.CreateNotification: TMiletusNotification;
begin
  Result := TMiletusNotification.Create;
end;

function TMiletusNotificationCenter.CreateNotification(const AName,
  AAlertBody: string): TMiletusNotification;
begin
  Result := TMiletusNotification.Create;
  Result.Name := AName;
  Result.AlertBody := AAlertBody;
end;

procedure TMiletusNotificationCenter.PresentNotification(
  const ANotification: TMiletusNotification);
var
  o: TJSONObject;
begin
  if Assigned(ANotification) then
  begin
    o := TJSONObject.Create;
    o.AddPair('Name', ANotification.Name);
    o.AddPair('AlertBody', ANotification.AlertBody);
    o.AddPair('Title', ANotification.Title);
    SendMessageToMiletus(NM_NOTIFICATION, o);
  end;
end;

{ TMiletusShell }

procedure TMiletusShell.Beep;
begin
  SendMessageToMiletus(NM_BEEP, TJSONObject.Create);
end;

function TMiletusShell.Execute(ACommand, AWorkingDir: string): TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    var
      o: TJSONObject;
    begin
      TTMSMiletusHelperRec.FMiletusShellExecute :=
      procedure()
      begin
        AResolve(TTMSMiletusHelperRec.FMiletusStringres);
      end;
      o := TJSONObject.Create;
      o.AddPair('Command', ACommand);
      o.AddPair('WorkDir', AWorkingDir);
      SendMessageToMiletus(NM_SHELL_EXECUTE, o);
    end);
end;

procedure TMiletusShell.MoveItemToTrash(FullPath: string);
var
  o: TJSONObject;
begin
  o := TJSONObject.Create;
  o.AddPair('Path', FullPath);
  SendMessageToMiletus(NM_DELETEFILE, o);
end;

procedure TMiletusShell.OpenExternal(URL: string);
var
  o: TJSONObject;
begin
  o := TJSONObject.Create;
  o.AddPair('Path', URL);
  SendMessageToMiletus(NM_OPENURL, o);
end;

procedure TMiletusShell.OpenItem(FullPath: string);
var
  o: TJSONObject;
begin
  o := TJSONObject.Create;
  o.AddPair('Path', FullPath);
  SendMessageToMiletus(NM_OPENPATH, o);
end;

procedure TMiletusShell.ShowItemInFolder(FullPath: string);
var
  o: TJSONObject;
begin
  o := TJSONObject.Create;
  o.AddPair('Path', FullPath);
  SendMessageToMiletus(NM_SHOWFILE, o);
end;

{ TMiletusErrorBox }

procedure TMiletusErrorBox.Execute;
var
  o: TJSONObject;
begin
  o := TJSONObject.Create;
  o.AddPair('Title', Title);
  o.AddPair('Content', Content);
  SendMessageToMiletus(NM_ERRORDLG, o);
end;

{ TMiletusMessageBox }

constructor TMiletusMessageBox.Create(AOwner: TComponent);
var
  g: TGUID;
begin
  inherited;
  FNoLink := True;
  VerificationChecked := False;
  FType := mbtNone;
  FButtons := TStringList.Create;
  CreateGUID(g);
  FGUID := GUIDToString(g);
end;

destructor TMiletusMessageBox.Destroy;
begin
  FButtons.free;
  inherited;
end;

procedure TMiletusMessageBox.Execute(AProc: TMiletusMessageBoxProc);
var
  o: TJSONObject;
  arr: TJSONArray;
  I: Integer;
begin
  FMessageProc := AProc;
  o := TJSONObject.Create;
  o.AddPair('GUID', FGUID);
  o.AddPair('Caption', FCaption);
  o.AddPair('Title', FTitle);
  o.AddPair('Text', FText);
  o.AddPair('NoLink', TJSONValue.Create(FNoLink));
  o.AddPair('VerificationChecked', TJSONValue.Create(FVerificationChecked));
  o.AddPair('VerificationText', FVerificationText);
  case DialogType of
    mbtNone: o.AddPair('DialogType', 'none');
    mbtInfo: o.AddPair('DialogType', 'info');
    mbtError: o.AddPair('DialogType', 'error');
    mbtQuestion: o.AddPair('DialogType', 'question');
    mbtWarning: o.AddPair('DialogType', 'warn');
  end;
  arr := TJSONArray.Create;
  for I := 0 to FButtons.Count - 1 do
    arr.Add(Buttons[I]);
  o.AddPair('Buttons', arr);
  SendMessageToMiletus(NM_MESSAGEBOX_OPEN, o);
end;

procedure TMiletusMessageBox.ExecuteSync;
var
  o: TJSONObject;
  v: TJSONValue;
  arr: TJSONArray;
  I: Integer;
  js: TJSON;
  res: string;
begin
  o := TJSONObject.Create;
  o.AddPair('GUID', FGUID);
  o.AddPair('IsAsync', TJSONValue.Create(False));
  o.AddPair('Caption', FCaption);
  o.AddPair('Title', FTitle);
  o.AddPair('Text', FText);
  o.AddPair('NoLink', TJSONValue.Create(FNoLink));
  o.AddPair('VerificationChecked', TJSONValue.Create(FVerificationChecked));
  o.AddPair('VerificationText', FVerificationText);
  case DialogType of
    mbtNone: o.AddPair('DialogType', 'none');
    mbtInfo: o.AddPair('DialogType', 'info');
    mbtError: o.AddPair('DialogType', 'error');
    mbtQuestion: o.AddPair('DialogType', 'question');
    mbtWarning: o.AddPair('DialogType', 'warn');
  end;
  arr := TJSONArray.Create;
  for I := 0 to FButtons.Count - 1 do
    arr.Add(Buttons[I]);
  o.AddPair('Buttons', arr);
  SendMessageToMiletusSync(NM_MESSAGEBOX_OPEN, o);

  res := GetDialogMessageSync;
  if res <> '' then
  begin
    js := TJSON.Create;
    v := js.Parse(res);
    if v is TJSONObject then
    begin
      o := v as TJSONObject;
      Response := StrToInt(o.GetJSONValue('ModalResult'));
      if StrToBool(o.GetJSONValue('VerificationChecked')) then
        VerificationChecked := True
      else
        VerificationChecked := False;
    end;
  end;
end;

procedure TMiletusMessageBox.ObserverChange(AMessageID: Integer;
  AMessageData: TJSONValue);
var
  b: Boolean;
  s: string;
begin
  inherited;
  if AMessageID = NM_MESSAGEBOX_OPEN then
  begin
    s := TJSONObject(AMessageData).GetJSONValue('GUID');
    if (s = '') or (FGUID <> s) then
      Exit;

    Response := StrToInt(TJSONObject(AMessageData).GetJSONValue('ModalResult'));
    if StrToBool(TJSONObject(AMessageData).GetJSONValue('VerificationChecked')) then
      b := True
    else
      b := False;

    VerificationChecked := b;

    if Assigned(OnExecute) then
      OnExecute(Self, b);

    if Assigned(FMessageProc) then
    begin
      FMessageProc(b);
      FMessageProc := nil;
    end;
  end;
end;

procedure TMiletusMessageBox.SetButtons(const Value: TStringList);
begin
  FButtons.Assign(Value);
end;

{ TMiletusFileWatch }

constructor TMiletusFileWatch.Create(ACollection: TCollection);
begin
  inherited;
  FFileName := '';
end;

procedure TMiletusFileWatch.SetFileName(const Value: string);
var
  o: TJSONObject;
begin
  if FFileName <> Value then
  begin
    if FFileName <> '' then
    begin
      o := TJSONObject.Create;
      o.AddPair('Path', FFileName);
      SendMessageToMiletus(NM_FILEWATCH_REMOVE, o);
    end;
    FFileName := Value;
    o := TJSONObject.Create;
    o.AddPair('Path', FFileName);
    SendMessageToMiletus(NM_FILEWATCH_ADD, o);
  end;
end;

{ TMiletusFileWatches }

function TMiletusFileWatches.Add: TMiletusFileWatch;
begin
  Result := TMiletusFileWatch(inherited Add);
end;

function TMiletusFileWatches.GetItem(Index: Integer): TMiletusFileWatch;
begin
  Result := TMiletusFileWatch(inherited Items[Index]);
end;

procedure TMiletusFileWatches.SetItem(Index: Integer;
  const Value: TMiletusFileWatch);
begin
  inherited Items[Index] := Value;
end;

{ TMiletusFileWatcher }

constructor TMiletusFileWatcher.Create(AOwner: TComponent);
begin
  inherited;
  FFiles := TMiletusFileWatches.Create(Self, TMiletusFileWatch);
end;

destructor TMiletusFileWatcher.Destroy;
begin
  FFiles.Free;
  inherited;
end;

procedure TMiletusFileWatcher.ObserverChange(AMessageID: Integer;
  AMessageData: TJSONValue);
var
  s: string;
  I: Integer;
  fw: TMiletusFileWatch;
begin
  inherited;
  if AMessageID = NM_FILEWATCH_CHANGE then
  begin
    s := TJSONObject(AMessageData).GetJSONValue('Path');
    for I := 0 to FFiles.Count - 1 do
    begin
      fw := FFiles.Items[I];
      if fw.FileName = s then
      begin
        if Assigned(fw.OnChange) then
          fw.OnChange(fw);
      end;
    end;
  end;
end;

{ TMiletusShortcut }

destructor TMiletusShortcut.Destroy;
var
  o: TJSONObject;
begin
  o := TJSONObject.Create;
  o.AddPair('Shortcut', FShortcut);
  SendMessageToMiletus(NM_SHORTCUT_REMOVE, o);
  inherited;
end;

procedure TMiletusShortcut.SetShortcut(const Value: TShortcut);
var
  o: TJSONObject;
begin
  if FShortcut <> Value then
  begin
    o := TJSONObject.Create;
    o.AddPair('Shortcut', FShortcut);
    SendMessageToMiletus(NM_SHORTCUT_REMOVE, o);
    FShortCut := Value;
    o := TJSONObject.Create;
    o.AddPair('Shortcut', FShortcut);
    SendMessageToMiletus(NM_SHORTCUT_ADD, o);
  end;
end;

{ TMiletusShortcuts }

function TMiletusShortcuts.Add: TMiletusShortcut;
begin
  Result := TMiletusShortcut(inherited Add);
end;

function TMiletusShortcuts.GetItem(Index: Integer): TMiletusShortcut;
begin
  Result := TMiletusShortcut(inherited Items[Index]);
end;

procedure TMiletusShortcuts.SetItem(Index: Integer;
  const Value: TMiletusShortcut);
begin
  inherited Items[Index] := Value;
end;

{ TMiletusGlobalShortcuts }

constructor TMiletusGlobalShortcuts.Create(AOwner: TComponent);
begin
  inherited;
  FShortcuts := TMiletusShortcuts.Create(Self, TMiletusShortcut);
end;

destructor TMiletusGlobalShortcuts.Destroy;
begin
  FShortcuts.Free;
  inherited;
end;

procedure TMiletusGlobalShortcuts.ObserverChange(AMessageID: Integer;
  AMessageData: TJSONValue);
var
  c, I: Integer;
  sc: TMiletusShortcut;
begin
  inherited;
  if AMessageID = NM_SHORTCUT_CHANGE then
  begin
    c := StrToInt(TJSONObject(AMessageData).GetJSONValue('Shortcut'));
    for I := 0 to FShortcuts.Count - 1 do
    begin
      sc := FShortcuts.Items[I];
      if sc.Shortcut = c then
      begin
        if Assigned(sc.OnChange) then
          sc.OnChange(sc);
      end;
    end;
  end;
end;

{ TMiletusTrayIcon }

constructor TMiletusTrayIcon.Create(AOwner: TComponent);
begin
  inherited;
  FHint := '';
  FIconURL := '';
end;

procedure TMiletusTrayIcon.DoCreateTrayIcon;
var
  o, pm: TJSONObject;
begin
  o := TJSONObject.Create;
  o.AddPair('Hint', FHint);
  o.AddPair('Icon', FIconURL);
  if Assigned(FPopupMenu) then
  begin
    pm := FPopupMenu.GeneratePopupMenu(-1, -1);
    o.AddPair('PopupMenu', pm);
  end;
  SendMessageToMiletus(NM_TRAYICON_UPDATE, o);
end;

procedure TMiletusTrayIcon.EndUpdate;
begin
  inherited;
  DoCreateTrayIcon;
end;

procedure TMiletusTrayIcon.Notification(AComponent: TComponent;
  Operation: TOperation);
begin
  inherited;
  if not (csDestroying in ComponentState) then
  begin
    if (Operation = opRemove) then
    begin
      if (AComponent = FPopupMenu) then
        FPopupMenu := nil;
    end;
  end;
end;

procedure TMiletusTrayIcon.ObserverChange(AMessageID: Integer;
  AMessageData: TJSONValue);
var
  mi: TMenuItem;
  p, i: Integer;
  mis: string;
begin
  inherited;
  if AMessageID = NM_TRAYICON_CLICK then
  begin
    if Assigned(OnClick) then
      OnClick(Self);
  end
  else if AMessageID = NM_TRAYICON_MENUCLICK then
  begin
    if Assigned(FPopupMenu) then
    begin
      mis := TJSONObject(AMessageData).GetJSONValue('MenuItemIndex');
      p := Pos('-', mis);
      while p > 0 do
      begin
        i := StrToInt(Copy(mis, 1, 1));
        if not Assigned(mi) then
          mi := FPopupMenu.Items.Items[i]
        else
          mi := mi.Items.Items[i];

        Delete(mis, 1, p);
        p := Pos('-', mis);
      end;
      i := StrToInt(Copy(mis, 1, 1));
      if not Assigned(mi) then
        mi := FPopupMenu.Items.Items[i]
      else
        mi := mi.Items.Items[i];

      if Assigned(mi.OnClick) then
        mi.OnClick(mi);
    end;
  end;
end;

procedure TMiletusTrayIcon.SetHint(const Value: string);
begin
  FHint := Value;
end;

{ TMiletusWindow }

procedure TMiletusWindow.Close;
var
  o: TJSONObject;
begin
  o := TJSONObject.Create;
  o.AddPair('FormClass', FFormClass.ClassName);
  SendMessageToMiletus(NM_WINDOW_CLOSE, o);
end;

procedure TMiletusWindow.Hide;
var
  o: TJSONObject;
begin
  o := TJSONObject.Create;
  o.AddPair('FormClass', FFormClass.ClassName);
  SendMessageToMiletus(NM_WINDOW_HIDE, o);
end;

procedure TMiletusWindow.LoadFromURL(URL: string);
var
  o: TJSONObject;
begin
  o := TJSONObject.Create;
  o.AddPair('FormClass', FFormClass.ClassName);
  o.AddPair('URL', URL);
  SendMessageToMiletus(NM_WINDOW_LOADURL, o);
end;

procedure TMiletusWindow.ObserverChange(AMessageID: Integer; AMessageData: TJSONValue);
var
  s: string;
begin
  inherited;
  s := TJSONObject(AMessageData).GetJSONValue('FormClass');
  if (s <> '') and (FormClass.ClassName = s) then
  begin
    case AMessageID of
      NM_WINDOW_CLOSE:
      begin
        if Assigned(OnClose) then
          OnClose(Self);
      end;
      NM_WINDOW_SHOW:
      begin
        if Assigned(OnShow) then
          OnShow(Self);
      end;
      NM_WINDOW_HIDE:
      begin
        if Assigned(OnHide) then
          OnHide(Self);
      end;
      NM_WINDOW_RESIZE:
      begin
        if Assigned(OnResize) then
          OnResize(Self);
      end;
      NM_WINDOW_MAXIMIZE:
      begin
        if Assigned(OnMaximize) then
          OnMaximize(Self);
      end;
      NM_WINDOW_MINIMIZE:
      begin
        if Assigned(OnMinimize) then
          OnMinimize(Self);
      end;
    end;
  end;
end;

procedure TMiletusWindow.Show;
var
  o: TJSONObject;
begin
  o := TJSONObject.Create;
  o.AddPair('UnitName', FFormClass.UnitName);
  o.AddPair('FormClass', FFormClass.ClassName);
  SendMessageToMiletus(NM_WINDOW_SHOW, o);
end;

procedure TMiletusWindow.ShowModal;
var
  o: TJSONObject;
begin
  o := TJSONObject.Create;
  o.AddPair('UnitName', FFormClass.UnitName);
  o.AddPair('FormClass', FFormClass.ClassName);
  SendMessageToMiletus(NM_WINDOW_SHOWMODAL, o);
end;

{ TMiletusObserver }

constructor TMiletusObserver.Create(AOwner: TComponent);
begin
  inherited;
  FObserverEnabled := True;
  MiletusCommunication.RegisterObserver(Self);
end;

destructor TMiletusObserver.Destroy;
begin
  MiletusCommunication.UnregisterObserver(Self);
  inherited;
end;

procedure TMiletusObserver.ObserverChange(AMessageID: Integer; AMessageData: TJSONValue);
begin
  if Assigned(OnObserverChange) then
    OnObserverChange(Self, AMessageId, AMessageData);
end;

{ TMiletusForm }

procedure TMiletusForm.Close;
var
  o: TJSONObject;
begin
  inherited;
  o := TJSONObject.Create;
  o.AddPair('FormClass', ClassName);
  InternalSendMessageToMiletus(NM_WINDOW_CLOSE, o);
end;

procedure TMiletusForm.CreateInitialize;
begin
  inherited;
  {$IFDEF FREEWARE}
  FNotifyCount := 0;
  {$ENDIF}
  FObserver := TMiletusObserver.Create(Self);
  FObserver.OnObserverChange := DoReceiveFormMessage;
  FBorderIcons := [biSystemMenu, biMinimize, biMaximize];
  FBorderStyle := bsSizeableBorder;
  FDefaultMonitor := dmActiveForm;
  FScreenSnap := False;
  FWindowState := wsNormal;
  FSnapBuffer := 10;
end;

procedure TMiletusForm.DoReceiveFormMessage(Sender: TObject; AMessageID: Integer;
  AData: TJSONValue);
var
  s: string;
begin
  case AMessageID of
    NM_WINDOW_MSGGET:
    begin
      s := TJSONObject(AData).GetJSONValue('FormID');
      if s = FFormID then
      begin
        if Assigned(OnFormMessage) then
          OnFormMessage(Self, TJSONObject(AData).GetJSONValue('SenderFormID'), TJSONObject(AData).GetJSONValue('Message'));
      end;
    end;
  end;
end;

procedure TMiletusForm.EndUpdate;
begin
  inherited;
end;

procedure TMiletusForm.InternalSendMessageToMiletus(AMessageID: Integer;
  AMessageData: TJSONObject);
begin
  SendMessageToMiletus(AMessageID, AMessageData);
end;

procedure TMiletusForm.LoadedDone;
var
  o: TJSONObject;
begin
  o := TJSONObject.Create;
  o.AddPair('ScreenSnap', BoolToStr(ScreenSnap, 'true', 'false'));
  o.AddPair('SnapBuffer', SnapBuffer);
  o.AddPair('FormClass', ClassName);
  {$IFDEF FREEWARE}
  if FNotifyCount = 0 then
  begin
    {$IFDEF TMS}
    o.AddPair('Freeware', 'TMS WEB Core Miletus \u00A9 2018 - 2022 tmssoftware.com - Trial Version');
    {$ENDIF}
    {$IFNDEF TMS}
    o.AddPair('Freeware', 'Application created with an unlicensed trial version of software');
    {$ENDIF}
  end;
  inc(FNotifyCount);
  {$ENDIF}
  InternalSendMessageToMiletus(NM_WINDOW_UPDATE, o);
  //inherited;
end;

procedure TMiletusForm.RegisterForm(FormID: string);
var
  o: TJSONObject;
begin
  FFormID := FormID;
  o := TJSONObject.Create;
  o.AddPair('FormID', FormID);
  InternalSendMessageToMiletus(NM_WINDOW_MSGREG, o);
end;

procedure TMiletusForm.SendMessage(FormID, AMessage: string);
var
  o: TJSONObject;
begin
  if FFormID <> '' then
  begin
    o := TJSONObject.Create;
    o.AddPair('SenderFormID', FFormID);
    o.AddPair('FormID', FormID);
    o.AddPair('Message', AMessage);
    InternalSendMessageToMiletus(NM_WINDOW_MSGSEND, o);
  end;
end;

procedure TMiletusForm.SetBorderIcons(const Value: TBorderIcons);
var
  o: TJSONObject;
  icon: TBorderIcon;
  arr: TJSONArray;
begin
  FBorderIcons := Value;
  if (csLoading in ComponentState) then
    Exit;

  o := TJSONObject.Create;
  arr := TJSONArray.Create;
  for icon := Low(TBorderIcons) to High(TBorderIcons) do
  begin
    if icon in Value then
    begin
      case icon of
        biSystemMenu: arr.Add('biSystemMenu');
        biMinimize: arr.Add('biMinimize');
        biMaximize: arr.Add('biMaximize');
        biHelp: arr.Add('biHelp');
      end;
    end;
  end;
  o.AddPair('BorderIcons', arr);
  InternalSendMessageToMiletus(NM_WINDOW_UPDATE, o);
end;

procedure TMiletusForm.SetBorderStyle(const Value: TMiletusFormBorderStyle);
var
  o: TJSONObject;
begin
  FBorderStyle := Value;
  if (csLoading in ComponentState) then
    Exit;

  o := TJSONObject.Create;
  case Value of
    bsNoneBorder: o.AddPair('BorderStyle', 'bsNoneBorder');
    bsSingleBorder: o.AddPair('BorderStyle', 'bsSingleBorder');
    bsDialogBorder: o.AddPair('BorderStyle', 'bsDialogBorder');
    bsSizeableBorder: o.AddPair('BorderStyle', 'bsSizeableBorder');
    bsToolWindowBorder: o.AddPair('BorderStyle', 'bsToolWindowBorder');
    bsSizeToolWinBorder: o.AddPair('BorderStyle', 'bsSizeToolWinBorder');
  end;
  InternalSendMessageToMiletus(NM_WINDOW_UPDATE, o);
end;

procedure TMiletusForm.SetBorderWidth(const Value: integer);
var
  o: TJSONObject;
begin
  inherited;
  o := TJSONObject.Create;
  o.AddPair('BorderWidth', Value);
  InternalSendMessageToMiletus(NM_WINDOW_UPDATE, o);
end;

procedure TMiletusForm.SetCaption(const AValue: string);
var
 o: TJSONObject;
begin
  inherited;
  o := TJSONObject.Create;
  o.AddPair('Caption', AValue);
  InternalSendMessageToMiletus(NM_WINDOW_UPDATE, o);
end;

procedure TMiletusForm.SetDefaultMonitor(const Value: TDefaultMonitor);
var
 o: TJSONObject;
begin
  FDefaultMonitor := Value;
  if (csLoading in ComponentState) then
    Exit;

  o := TJSONObject.Create;
  case Value of
    dmDesktop: o.AddPair('DefaultMonitor', 'dmDesktop');
    dmPrimary: o.AddPair('DefaultMonitor', 'dmPrimary');
    dmMainForm: o.AddPair('DefaultMonitor', 'dmMainForm');
    dmActiveForm: o.AddPair('DefaultMonitor', 'dmActiveForm');
  end;
  InternalSendMessageToMiletus(NM_WINDOW_UPDATE, o);
end;

procedure TMiletusForm.SetFormStyle(const Value: TFormStyle);
var
  o: TJSONObject;
begin
  inherited;
  if (csLoading in ComponentState) then
    Exit;

  o := TJSONObject.Create;
  case Value of
    fsNormal: o.AddPair('FormStyle', 'fsNormal');
    fsStayOnTop: o.AddPAir('FormStyle', 'fsStayOnTop');
  end;
  InternalSendMessageToMiletus(NM_WINDOW_UPDATE, o);
end;

procedure TMiletusForm.SetHeight(AValue: Integer);
var
  o: TJSONObject;
begin
  inherited;
  if (csLoading in ComponentState) then
    Exit;

  o := TJSONObject.Create;
  o.AddPair('Height', AValue);
  InternalSendMessageToMiletus(NM_WINDOW_UPDATE, o);
end;

procedure TMiletusForm.SetPosition(const Value: TPosition);
var
  o: TJSONObject;
begin
  inherited;
  if (csLoading in ComponentState) then
    Exit;

  o := TJSONObject.Create;
  case Value of
    poDesigned: o.AddPair('Position', 'poDesigned');
    poDefault: o.AddPair('Position', 'poDefault');
    poDefaultPosOnly: o.AddPair('Position', 'poDefaultPosOnly');
    poDefaultSizeOnly: o.AddPair('Position', 'poDefaultSizeOnly');
    poScreenCenter: o.AddPair('Position', 'poScreenCenter');
    poDesktopCenter: o.AddPair('Position', 'poDesktopCenter');
    poMainFormCenter: o.AddPair('Position', 'poMainFormCenter');
    poOwnerFormCenter: o.AddPair('Position', 'poOwnerFormCenter');
  end;
  InternalSendMessageToMiletus(NM_WINDOW_UPDATE, o);
end;

procedure TMiletusForm.SetSnapBuffer(const Value: Integer);
var
  o: TJSONObject;
begin
  FSnapBuffer := Value;
  if (csLoading in ComponentState) then
    Exit;

  o := TJSONObject.Create;
  o.AddPair('SnapBuffer', Value);
  InternalSendMessageToMiletus(NM_WINDOW_UPDATE, o);
end;

procedure TMiletusForm.SetScreenSnap(const Value: Boolean);
var
  o: TJSONObject;
begin
  FScreenSnap := Value;
  if (csLoading in ComponentState) then
    Exit;

  o := TJSONObject.Create;
  o.AddPair('ScreenSnap', BoolToStr(Value, 'true', 'false'));
  InternalSendMessageToMiletus(NM_WINDOW_UPDATE, o);
end;

procedure TMiletusForm.SetWidth(AValue: Integer);
var
  o: TJSONObject;
begin
  inherited;
  if (csLoading in ComponentState) then
    Exit;

  o := TJSONObject.Create;
  o.AddPair('Width', AValue);
  InternalSendMessageToMiletus(NM_WINDOW_UPDATE, o);
end;

procedure TMiletusForm.SetWindowState(const Value: TWindowState);
var
  o: TJSONObject;
begin
  FWindowState := Value;
  if (csLoading in ComponentState) then
    Exit;

  o := TJSONObject.Create;
  case Value of
    wsNormal: o.AddPair('WindowState', 'wsNormal');
    wsMinimized: o.AddPair('WindowState', 'wsMinimized');
    wsMaximized: o.AddPair('WindowState', 'wsMaximized');
  end;
  InternalSendMessageToMiletus(NM_WINDOW_UPDATE, o);
end;

var
  FMiletusShell: TMiletusShell;
  FMiletusClipboard: TMiletusClipboard;
  FMiletusUpdate: TMiletusUpdate;

function MiletusShell: TMiletusShell;
begin
  if not Assigned(FMiletusShell) then
    FMiletusShell := TMiletusShell.Create;

  Result := FMiletusShell;
end;

function MiletusClipboard: TMiletusClipboard;
begin
  if not Assigned(FMiletusClipboard) then
    FMiletusClipboard := TMiletusClipboard.Create;

  Result := FMiletusClipboard;
end;

function MiletusUpdate: TMiletusUpdate;
begin
  if not Assigned(FMiletusUpdate) then
    FMiletusUpdate := TMiletusUpdate.Create;

  Result := FMiletusUpdate;
end;

{ TMiletusClipboard }

function TMiletusClipboard.GetAsText: string;
var
  o: TJSONObject;
  res: string;
begin
  Result := '';
  SendMessageToMiletusSync(NM_CLIPBOARD_GET, TJSONObject.Create);
  res := GetObjectMessageSync;
  if res <> '' then
  begin
    o := TJSONObject(TJSONObject.ParseJSONValue(res));
    Result := o.GetJSONValue('Text');
  end;
end;

function TMiletusClipboard.GetAsTextP: TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    begin
      TTMSMiletusHelperRec.FMiletusClipboardTextReceived :=
      procedure()
      begin
        AResolve(TTMSMiletusHelperRec.FMiletusClipboardAsText);
      end;
      SendMessageToMiletus(NM_CLIPBOARD_GET, TJSONObject.Create);
    end);
end;

function TMiletusClipboard.HasFormat(AFormat: Word): Boolean;
var
  o: TJSONObject;
  res: string;
begin
  Result := False;
  o := TJSONObject.Create;
  o.AddPair('Format', AFormat);
  SendMessageToMiletusSync(NM_CLIPBOARD_FORMAT, o);
  res := GetObjectMessageSync;
  if res <> '' then
  begin
    o := TJSONObject(TJSONObject.ParseJSONValue(res));
    if StrToBool(o.GetJSONValue('HasFormat')) then
      Result := True;
  end;
end;

function TMiletusClipboard.HasFormatP(AFormat: Word): TJSPromise;
var
  o: TJSONObject;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    begin
      TTMSMiletusHelperRec.FMiletusClipboardFormatReceived :=
      procedure()
      begin
        AResolve(TTMSMiletusHelperRec.FMiletusClipboardHasFormat);
      end;
      o := TJSONObject.Create;
      o.AddPair('Format', AFormat);
      SendMessageToMiletus(NM_CLIPBOARD_FORMAT, o);
    end);
end;

procedure TMiletusClipboard.SetAsText(const Value: string);
var
  o: TJSONObject;
begin
  o := TJSONObject.Create;
  o.AddPair('Text', Value);
  SendMessageToMiletus(NM_CLIPBOARD_SET, o);
end;

{ TMiletusBinaryDataStream }

constructor TMiletusBinaryDataStream.Create;
var
  g: TGUID;
begin
  CreateGUID(g);
  FGUID := GUIDToString(g);
  FObserver := TMiletusObserver.Create(nil);
  FObserver.OnObserverChange := DoStringListResponse;
end;

destructor TMiletusBinaryDataStream.Destroy;
begin
  FObserver.Free;
  inherited;
end;

procedure TMiletusBinaryDataStream.DoStringListResponse(Sender: TObject;
  AMessageID: Integer; AData: TJSONValue);
var
  s: string;
begin
  if AMessageID = NM_STRINGLIST_LOAD then
  begin
    s := TJSONObject(AData).GetJSONValue('GUID');
    if (s = '') or (FGUID <> s) then
      Exit;

    if not Assigned(TJSONObject(AData).Get('Bytes')) then
      raise Exception.Create('File does not exist.');

    FBase64 := TJSONObject(AData).GetJSONValue('Bytes');

    if Assigned(FLoadProc) then
      FLoadProc;
    FLoadProc := nil;
  end;
end;

function TMiletusBinaryDataStream.GetArrayBuffer: TJSArrayBuffer;
  function GetCharCode(element: JSValue; index: NativeInt; anArray: TJSTypedArray): JSValue;
  begin
    Result := TJSString.new(JS.toString(element)).charCodeAt(0);
  end;
var
  ua: TJSUint8Array;
begin
  ua := TJSUint8Array.from(Text, @GetCharCode);
  Result := ua.buffer;
end;

function TMiletusBinaryDataStream.GetAsStream: TStream;
var
  arr: TJSArrayBuffer;
  b: TBytes;
begin
  Result := TMemoryStream.Create;

  arr := GetArrayBuffer;
  b := TMemoryStream.MemoryToBytes(arr);

  Result.Write(b, arr.bytelength);
  Result.Position := 0;
end;

function TMiletusBinaryDataStream.GetText: string;
begin
  Result := window.atob(FBase64);
end;

function TMiletusBinaryDataStream.HandleAbort(
  Event: TEventListenerEvent): Boolean;
begin
  Result := True;
end;

function TMiletusBinaryDataStream.HandleResponse(
  Event: TEventListenerEvent): Boolean;
var
  s, sData: string;
  jsObj: TJSObject;
begin
  Result := True;
  sData := '';
  asm
    sData = Event.target.responseText;
  end;

  jsObj := TJSJSON.parseObject(sData);
  s := JS.ToString(jsObj.Properties['GUID']);
  if (s = '') or (FGUID <> s) then
    Exit;

  FBase64 := JS.ToString(jsObj.Properties['Bytes']);

  if Assigned(FLoadProc) then
    FLoadProc;

  FLoadProc := nil;
end;

procedure TMiletusBinaryDataStream.InternalSaveToFile(const FileName: string;
  ASync: Boolean);
var
  o: TJSONObject;
begin
  o := TJSONObject.Create;
  o.AddPair('FileName', FileName);
  o.AddPair('Bytes', FBase64);
  if ASync then
    SendMessageToMiletus(NM_STRINGLIST_SAVE, o)
  else
    SendMessageToMiletusSync(NM_STRINGLIST_SAVE, o);
  o.Free;
end;

procedure TMiletusBinaryDataStream.LoadFromFile(const FileName: string);
var
  o: TJSONObject;
  res: string;
begin
  o := TJSONObject.Create;
  o.AddPair('IsAsync', TJSONValue.Create(False));
  o.AddPair('IsString', TJSONValue.Create(False));
  o.AddPair('GUID', FGUID);
  o.AddPair('FileName', FileName);
  SendMessageToMiletusSync(NM_STRINGLIST_LOAD, o);
  res := GetStringListMessageSync;
  if res <> '' then
  begin
    o := TJSONObject(TJSONObject.ParseJSONValue(GetStringListMessageSync));
    FBase64 := o.GetJSONValue('Bytes');
  end
  else
    FBase64 := '';
  o.Free;
end;

procedure TMiletusBinaryDataStream.LoadFromFileAsync(const FileName: string;
  AProc: TMiletusLoadFileProc);
var
  o: TJSONObject;
begin
  FLoadProc := AProc;
  o := TJSONObject.Create;
  o.AddPair('IsAsync', TJSONValue.Create(True));
  o.AddPair('IsString', TJSONValue.Create(False));
  o.AddPair('GUID', FGUID);
  o.AddPair('FileName', FileName);
  SendMessageToMiletus(NM_STRINGLIST_LOAD, o);
  o.Free;
end;

procedure TMiletusBinaryDataStream.LoadFromFileRequest(const FileName: string;
  AProc: TMiletusLoadFileProc);
var
  req: TJSXMLHttpRequest;
begin
  FLoadProc := AProc;
  req := TJSXMLHttpRequest.new;
  req.addEventListener('load', @HandleResponse);
  req.addEventListener('abort',@HandleAbort);
  req.addEventListener('timeout',@HandleAbort);
  req.addEventListener('error',@HandleAbort);
  req.open('GET', 'http://localhost:' + window.location.port + '/?localfile=' + FileName + '&guid=' + FGUID);
  req.send;
end;

procedure TMiletusBinaryDataStream.SaveToFile(const FileName: string);
begin
  InternalSaveToFile(FileName, False);
end;

procedure TMiletusBinaryDataStream.SaveToFileAsync(const FileName: string);
begin
  InternalSaveToFile(FileName, True);
end;

procedure TMiletusBinaryDataStream.SaveToFileRequest(const FileName: string);
var
  o: TJSONObject;
  req: TJSXMLHttpRequest;
begin
  o := TJSONObject.Create;
  o.AddPair('FileName', FileName);
  o.AddPair('Bytes', FBase64);
  req := TJSXMLHttpRequest.new;
//  req.addEventListener('load', @HandleResponse);
  req.addEventListener('abort',@HandleAbort);
  req.addEventListener('timeout',@HandleAbort);
  req.addEventListener('error',@HandleAbort);
  req.open('POST', 'http://localhost:' + window.location.port + '/?localfile=' + FileName);
  req.send(o.ToString);
  o.Free;
end;

procedure TMiletusBinaryDataStream.SetArrayBuffer(const Value: TJSArrayBuffer);
var
  s: string;
begin
  s := '';
  asm
    let ua = new Uint8Array(Value);
    let len = ua.byteLength;
    for (var i = 0; i < len; i++) {
        s += String.fromCharCode(ua[i]);
    }
  end;
  Text := s;
end;

procedure TMiletusBinaryDataStream.SetAsStream(const Value: TStream);
begin
  if Value is TMemoryStream then
    SetArrayBuffer((Value as TMemoryStream).Memory);
end;

procedure TMiletusBinaryDataStream.SetText(const Value: string);
begin
  FBase64 := window.btoa(Value);
end;

{ TMiletusStream }

procedure TMiletusStream.LoadFromFile(AFilename: string);
var
  ds: TMiletusBinaryDataStream;
begin
  ds := TMiletusBinaryDataStream.Create;
  try
    ds.LoadFromFile(AFileName);
    LoadFromStream(ds.AsStream);
    Position := 0;
  finally
    ds.Free;
  end;
end;

procedure TMiletusStream.SaveToFile(AFilename: string);
var
  ds: TMiletusBinaryDataStream;
begin
  ds := TMiletusBinaryDataStream.Create;
  try
    ds.AsStream := Self;
    ds.SaveToFile(AFileName);
  finally
    ds.Free;
  end;
end;

{ TTMSParams }

function TMSParamCount: Integer;
begin
  Result := TTMSParams.FMiletusParamCount;
end;

function TMSParamStr(AIndex: Longint): string;
begin
  if (AIndex < 0) or (AIndex >= Length(TTMSParams.FMiletusParams)) then
    Result := ''
  else
    Result := TTMSParams.FMiletusParams[AIndex];
end;

class function TTMSParams.Execute: TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    begin
      if not FMiletusParamsCalculated then
      begin
        FMiletusParamsReceived :=
        procedure()
        begin
          OnParamCount := TMSParamCount;
          OnParamStr := TMSParamStr;
          AResolve(True);
        end;
        SendMessageToMiletus(NM_APP_PARAMS, TJSONObject.Create);
      end
      else
        AResolve(True);
    end);
end;

{ TMiletusINIFile }

constructor TMiletusINIFile.Create(AFileName: string);
var
  g: TGUID;
begin
  CreateGUID(g);
  FGUID := GUIDToString(g);
  FFileName := AFileName;
  FReadStringsRes := TStringList.Create;
  FObserver := TMiletusObserver.Create(nil);
  FObserver.OnObserverChange := DoObserverChange;
end;

procedure TMiletusINIFile.DeleteKey(const Section, Ident: string);
var
  o: TJSONObject;
begin
  o := TJSONObject.Create;
  o.AddPair('FileName', FFileName);
  o.AddPair('Section', Section);
  o.AddPair('NameOrIdent', Ident);
  SendMessageToMiletus(NM_INI_DELETE_KEY, o);
end;

destructor TMiletusINIFile.Destroy;
begin
  FObserver.Free;
  FReadStringsRes.Free;
  inherited;
end;

procedure TMiletusINIFile.DoObserverChange(Sender: TObject; AMessageID: Integer;
  AData: TJSONValue);
  function GetCharCode(element: JSValue; index: NativeInt; anArray: TJSTypedArray): JSValue;
  begin
    Result := TJSString.new(JS.toString(element)).charCodeAt(0);
  end;
var
  s: string;
  i: Integer;
  ua: TJSUint8Array;
  b: TBytes;
  arr: TJSONArray;
begin
  if AMessageID = NM_INI_READ then
  begin
    s := TJSONObject(AData).GetJSONValue('GUID');
    if (s = '') or (FGUID <> s) then
      Exit;

    i := StrToInt(TJSONObject(AData).GetJSONValue('Type'));
    case i of
      IR_BOOL:
      begin
        s := TJSONObject(AData).GetJSONValue('Value');
        FReadBoolRes := StrToBool(s);
      end;
      IR_DATE:
      begin
        s := TJSONObject(AData).GetJSONValue('Value');
        TryRFC3339ToDateTime(s, FReadDateRes);
      end;
      IR_DATETIME:
      begin
        s := TJSONObject(AData).GetJSONValue('Value');
        TryRFC3339ToDateTime(s, FReadDateTimeRes);
      end;
      IR_FLOAT:
      begin
        s := TJSONObject(AData).GetJSONValue('Value');
        FReadFloatRes := StrToFloat(s);
      end;
      IR_INTEGER:
      begin
        s := TJSONObject(AData).GetJSONValue('Value');
        FReadIntegerRes := StrToInt(s);
      end;
      IR_INT64:
      begin
        s := TJSONObject(AData).GetJSONValue('Value');
        FReadInt64Res := StrToInt64(s);
      end;
      IR_STRING:
      begin
        s := TJSONObject(AData).GetJSONValue('Value');
        FReadStringRes := s;
      end;
      IR_TIME:
      begin
        s := TJSONObject(AData).GetJSONValue('Value');
        TryRFC3339ToDateTime(s, FReadTimeRes);
      end;
      IR_BINARY_STREAM:
      begin
        s := TJSONObject(AData).GetJSONValue('Value');
        FReadStreamRes := TMemoryStream.Create;
        ua := TJSUint8Array.from(s, @GetCharCode);
        b := TMemoryStream.MemoryToBytes(ua.buffer);
        FReadStreamRes.Write(b, ua.buffer.bytelength);
        FReadStreamRes.Position := 0;
        FReadIntegerRes := StrToInt(TJSONObject(AData).GetJSONValue('Result'));
      end;
      IR_SECTION, IR_SECTIONS, IR_SECTION_VALUES, IR_SUBSECTIONS:
      begin
        arr := TJSONArray(TJSONObject(AData).GetValue('Value'));
        FReadStringsRes.BeginUpdate;
        FReadStringsRes.Clear;
        for I := 0 to arr.Count - 1 do
          FReadStringsRes.Add(arr.Items[I].Value);
        FReadStringsRes.EndUpdate;
      end;
    end;

    if Assigned(FReadValueProc) then
      FReadValueProc;
  end
  else if (AMessageID = NM_INI_SECTION_EXISTS) or (AMessageID = NM_INI_VALUE_EXISTS) then
  begin
    s := TJSONObject(AData).GetJSONValue('GUID');
    if (s = '') or (FGUID <> s) then
      Exit;

    s := TJSONObject(AData).GetJSONValue('Result');
    FReadBoolRes := StrToBool(s);

    if Assigned(FReadValueProc) then
      FReadValueProc;
  end;
end;

procedure TMiletusINIFile.EraseSection(const Section: string);
var
  o: TJSONObject;
begin
  o := TJSONObject.Create;
  o.AddPair('FileName', FFileName);
  o.AddPair('Section', Section);
  SendMessageToMiletus(NM_INI_ERASE_SECTION, o);
end;

procedure TMiletusINIFile.InternalWriteValue(const AType: Integer; const Section, NameOrIdent: string;
  Value: JSValue);
var
  o: TJSONObject;
begin
  o := TJSONObject.Create;
  o.AddPair('FileName', FFileName);
  o.AddPair('Type', AType);
  o.AddPair('Section', Section);
  o.AddPair('NameOrIdent', NameOrIdent);
  o.AddPair('Value', TJSONValue.Create(Value));
  SendMessageToMiletus(NM_INI_WRITE, o);
end;

function TMiletusINIFile.ReadBinaryStream(const Section, Name: string;
  Value: TStream): TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    var
      o: TJSONObject;
    begin
      FReadValueProc :=
      procedure()
      var
        i: Integer;
      begin
        if Assigned(FReadStreamRes) then
        begin
          i := Value.Position;
          Value.CopyFrom(FReadStreamRes, FReadStreamRes.Size);
          Value.Position := i;
        end;
        FReadStreamRes.Free;
        AResolve(FReadIntegerRes);
      end;
      o := TJSONObject.Create;
      o.AddPair('GUID', FGUID);
      o.AddPair('FileName', FFileName);
      o.AddPair('Section', Section);
      o.AddPair('NameOrIdent', Name);
      o.AddPair('Type', IR_BINARY_STREAM);
      SendMessageToMiletus(NM_INI_READ, o);
    end);
end;

function TMiletusINIFile.ReadBool(const Section, Ident: string; Default: Boolean): TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    var
      o: TJSONObject;
    begin
      FReadValueProc :=
      procedure()
      begin
        AResolve(FReadBoolRes);
      end;
      o := TJSONObject.Create;
      o.AddPair('GUID', FGUID);
      o.AddPair('FileName', FFileName);
      o.AddPair('Section', Section);
      o.AddPair('NameOrIdent', Ident);
      o.AddPair('Type', IR_BOOL);
      o.AddPair('Default', TJSONValue.Create(Default));
      SendMessageToMiletus(NM_INI_READ, o);
    end);
end;

function TMiletusINIFile.ReadDate(const Section, Name: string; Default: TDateTime): TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    var
      o: TJSONObject;
    begin
      FReadValueProc :=
      procedure()
      begin
        AResolve(FReadDateRes);
      end;
      o := TJSONObject.Create;
      o.AddPair('GUID', FGUID);
      o.AddPair('FileName', FFileName);
      o.AddPair('Section', Section);
      o.AddPair('NameOrIdent', Name);
      o.AddPair('Type', IR_DATE);
      o.AddPair('Default', TJSONValue.Create(DateTimeToJSDate(Default)));
      SendMessageToMiletus(NM_INI_READ, o);
    end);
end;

function TMiletusINIFile.ReadDateTime(const Section, Name: string; Default: TDateTime): TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    var
      o: TJSONObject;
    begin
      FReadValueProc :=
      procedure()
      begin
        AResolve(FReadDateTimeRes);
      end;
      o := TJSONObject.Create;
      o.AddPair('GUID', FGUID);
      o.AddPair('FileName', FFileName);
      o.AddPair('Section', Section);
      o.AddPair('NameOrIdent', Name);
      o.AddPair('Type', IR_DATETIME);
      o.AddPair('Default', TJSONValue.Create(DateTimeToJSDate(Default)));
      SendMessageToMiletus(NM_INI_READ, o);
    end);
end;

function TMiletusINIFile.ReadFloat(const Section, Name: string; Default: Double): TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    var
      o: TJSONObject;
    begin
      FReadValueProc :=
      procedure()
      begin
        AResolve(FReadFloatRes);
      end;
      o := TJSONObject.Create;
      o.AddPair('GUID', FGUID);
      o.AddPair('FileName', FFileName);
      o.AddPair('Section', Section);
      o.AddPair('NameOrIdent', Name);
      o.AddPair('Type', IR_FLOAT);
      o.AddPair('Default', TJSONValue.Create(Default));
      SendMessageToMiletus(NM_INI_READ, o);
    end);
end;

function TMiletusINIFile.ReadInt64(const Section, Ident: string; Default: Int64): TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    var
      o: TJSONObject;
    begin
      FReadValueProc :=
      procedure()
      begin
        AResolve(FReadInt64Res);
      end;
      o := TJSONObject.Create;
      o.AddPair('GUID', FGUID);
      o.AddPair('FileName', FFileName);
      o.AddPair('Section', Section);
      o.AddPair('NameOrIdent', Ident);
      o.AddPair('Type', IR_INT64);
      o.AddPair('Default', TJSONValue.Create(Default));
      SendMessageToMiletus(NM_INI_READ, o);
    end);
end;

function TMiletusINIFile.ReadInteger(const Section, Ident: string; Default: Integer): TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    var
      o: TJSONObject;
    begin
      FReadValueProc :=
      procedure()
      begin
        AResolve(FReadIntegerRes);
      end;
      o := TJSONObject.Create;
      o.AddPair('GUID', FGUID);
      o.AddPair('FileName', FFileName);
      o.AddPair('Section', Section);
      o.AddPair('NameOrIdent', Ident);
      o.AddPair('Type', IR_INTEGER);
      o.AddPair('Default', TJSONValue.Create(Default));
      SendMessageToMiletus(NM_INI_READ, o);
    end);
end;

function TMiletusINIFile.ReadSection(const Section: string;
  Strings: TStrings): TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    var
      o: TJSONObject;
    begin
      FReadValueProc :=
      procedure()
      var
        I: Integer;
      begin
        Strings.BeginUpdate;
        try
          Strings.Clear;
          for I := 0 to FReadStringsRes.Count - 1 do
            Strings.Add(FReadStringsRes[I]);
        finally
          Strings.EndUpdate;
        end;
        AResolve(nil);
      end;
      o := TJSONObject.Create;
      o.AddPair('GUID', FGUID);
      o.AddPair('FileName', FFileName);
      o.AddPair('Section', Section);
      o.AddPair('Type', IR_SECTION);
      SendMessageToMiletus(NM_INI_READ, o);
    end);
end;

function TMiletusINIFile.ReadSections(Strings: TStrings): TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    var
      o: TJSONObject;
    begin
      FReadValueProc :=
      procedure()
      var
        I: Integer;
      begin
        Strings.BeginUpdate;
        try
          Strings.Clear;
          for I := 0 to FReadStringsRes.Count - 1 do
            Strings.Add(FReadStringsRes[I]);
        finally
          Strings.EndUpdate;
        end;
        AResolve(nil);
      end;
      o := TJSONObject.Create;
      o.AddPair('GUID', FGUID);
      o.AddPair('FileName', FFileName);
      o.AddPair('Type', IR_SECTIONS);
      SendMessageToMiletus(NM_INI_READ, o);
    end);
end;

function TMiletusINIFile.ReadSectionValues(const Section: string;
  Strings: TStrings): TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    var
      o: TJSONObject;
    begin
      FReadValueProc :=
      procedure()
      var
        I: Integer;
      begin
        Strings.BeginUpdate;
        try
          Strings.Clear;
          for I := 0 to FReadStringsRes.Count - 1 do
            Strings.Add(FReadStringsRes[I]);
        finally
          Strings.EndUpdate;
        end;
        AResolve(nil);
      end;
      o := TJSONObject.Create;
      o.AddPair('GUID', FGUID);
      o.AddPair('FileName', FFileName);
      o.AddPair('Section', Section);
      o.AddPair('Type', IR_SECTION_VALUES);
      SendMessageToMiletus(NM_INI_READ, o);
    end);
end;

function TMiletusINIFile.ReadString(const Section, Ident: string; Default: string): TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    var
      o: TJSONObject;
    begin
      FReadValueProc :=
      procedure()
      begin
        AResolve(FReadStringRes);
      end;
      o := TJSONObject.Create;
      o.AddPair('GUID', FGUID);
      o.AddPair('FileName', FFileName);
      o.AddPair('Section', Section);
      o.AddPair('NameOrIdent', Ident);
      o.AddPair('Type', IR_STRING);
      o.AddPair('Default', TJSONValue.Create(Default));
      SendMessageToMiletus(NM_INI_READ, o);
    end);
end;

function TMiletusINIFile.ReadSubSections(const Section: string;
  Strings: TStrings; Recurse: Boolean): TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    var
      o: TJSONObject;
    begin
      FReadValueProc :=
      procedure()
      var
        I: Integer;
      begin
        Strings.BeginUpdate;
        try
          Strings.Clear;
          for I := 0 to FReadStringsRes.Count - 1 do
            Strings.Add(FReadStringsRes[I]);
        finally
          Strings.EndUpdate;
        end;
        AResolve(nil);
      end;
      o := TJSONObject.Create;
      o.AddPair('GUID', FGUID);
      o.AddPair('FileName', FFileName);
      o.AddPair('Section', Section);
      o.AddPair('Recurse', TJSONValue.Create(Recurse));
      o.AddPair('Type', IR_SUBSECTIONS);
      SendMessageToMiletus(NM_INI_READ, o);
    end);
end;

function TMiletusINIFile.ReadTime(const Section, Name: string; Default: TDateTime): TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    var
      o: TJSONObject;
    begin
      FReadValueProc :=
      procedure()
      begin
        AResolve(FReadTimeRes);
      end;
      o := TJSONObject.Create;
      o.AddPair('GUID', FGUID);
      o.AddPair('FileName', FFileName);
      o.AddPair('Section', Section);
      o.AddPair('NameOrIdent', Name);
      o.AddPair('Type', IR_TIME);
      o.AddPair('Default', TJSONValue.Create(DateTimeToJSDate(Default)));
      SendMessageToMiletus(NM_INI_READ, o);
    end);
end;

function TMiletusINIFile.SectionExists(const Section: string): TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    var
      o: TJSONObject;
    begin
      FReadValueProc :=
      procedure()
      begin
        AResolve(FReadBoolRes);
      end;
      o := TJSONObject.Create;
      o.AddPair('GUID', FGUID);
      o.AddPair('FileName', FFileName);
      o.AddPair('Section', Section);
      SendMessageToMiletus(NM_INI_SECTION_EXISTS, o);
    end);
end;

function TMiletusINIFile.ValueExists(const Section, Ident: string): TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    var
      o: TJSONObject;
    begin
      FReadValueProc :=
      procedure()
      begin
        AResolve(FReadBoolRes);
      end;
      o := TJSONObject.Create;
      o.AddPair('GUID', FGUID);
      o.AddPair('FileName', FFileName);
      o.AddPair('Section', Section);
      o.AddPair('NameOrIdent', Ident);
      SendMessageToMiletus(NM_INI_VALUE_EXISTS, o);
    end);
end;

{$HINTS OFF}
procedure TMiletusINIFile.WriteBinaryStream(const Section, Name: string;
  Value: TStream);
var
  s: string;
  buff: TJSArrayBuffer;
begin
  if Value is TMemoryStream then
  begin
    s := '';
    buff := (Value as TMemoryStream).Memory;
    asm
      let ua = new Uint8Array(buff);
      let len = ua.byteLength;
      for (var i = 0; i < len; i++) {
          s += String.fromCharCode(ua[i]);
      }
    end;
    InternalWriteValue(IR_BINARY_STREAM, Section, Name, s);
  end;
end;
{$HINTS ON}

procedure TMiletusINIFile.WriteBool(const Section, Ident: string;
  Value: Boolean);
begin
  InternalWriteValue(IR_BOOL, Section, Ident, Value);
end;

procedure TMiletusINIFile.WriteDate(const Section, Name: string;
  Value: TDateTime);
begin
  InternalWriteValue(IR_DATE, Section, Name, DateTimeToRFC3339(Value));
end;

procedure TMiletusINIFile.WriteDateTime(const Section, Name: string;
  Value: TDateTime);
begin
  InternalWriteValue(IR_DATETIME, Section, Name, DateTimeToRFC3339(Value));
end;

procedure TMiletusINIFile.WriteFloat(const Section, Name: string;
  Value: Double);
begin
  InternalWriteValue(IR_FLOAT, Section, Name, Value);
end;

procedure TMiletusINIFile.WriteInt64(const Section, Ident: string;
  Value: Int64);
begin
  InternalWriteValue(IR_INT64, Section, Ident, Value);
end;

procedure TMiletusINIFile.WriteInteger(const Section, Ident: string;
  Value: Integer);
begin
  InternalWriteValue(IR_INTEGER, Section, Ident, Value);
end;

procedure TMiletusINIFile.WriteString(const Section, Ident, Value: String);
begin
  InternalWriteValue(IR_STRING, Section, Ident, Value);
end;

procedure TMiletusINIFile.WriteTime(const Section, Name: string;
  Value: TDateTime);
begin
  InternalWriteValue(IR_TIME, Section, Name, DateTimeToRFC3339(Value));
end;

{ TMiletusRegistry }

function TMiletusRegistry.CloseKey: TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    var
      o: TJSONObject;
    begin
      FReadValueProc :=
      procedure()
      begin
        if FReject then
          AReject(nil)
        else
          AResolve(nil);
      end;
      o := TJSONObject.Create;
      o.AddPair('GUID', FGUID);
      SendMessageToMiletus(NM_REG_KEY_CLOSE, o);
    end);
end;

constructor TMiletusRegistry.Create(AAccess: LongWord);
var
  g: TGUID;
begin
  CreateGUID(g);
  FGUID := GUIDToString(g);
  FReadStringsRes := TStringList.Create;
  FObserver := TMiletusObserver.Create(nil);
  FObserver.OnObserverChange := DoObserverChange;
  FRootKey := MILETUS_CURRENT_USER;
  FAccess := AAccess;
end;

function TMiletusRegistry.CreateKey(const Key: string): TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    var
      o: TJSONObject;
    begin
      FReadValueProc :=
      procedure()
      begin
        if FReject then
          AReject(False)
        else
          AResolve(FReadBoolRes);
      end;
      o := TJSONObject.Create;
      o.AddPair('GUID', FGUID);
      o.AddPair('RootKey', FRootKey);
      o.AddPair('Access', IntToStr(FAccess));
      o.AddPair('Key', Key);
      SendMessageToMiletus(NM_REG_KEY_CREATE, o);
    end);
end;

function TMiletusRegistry.DeleteKey(const Key: string): TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    var
      o: TJSONObject;
    begin
      FReadValueProc :=
      procedure()
      begin
        if FReject then
          AReject(False)
        else
          AResolve(FReadBoolRes);
      end;
      o := TJSONObject.Create;
      o.AddPair('GUID', FGUID);
      o.AddPair('RootKey', FRootKey);
      o.AddPair('Access', IntToStr(FAccess));
      o.AddPair('Key', Key);
      SendMessageToMiletus(NM_REG_KEY_DELETE, o);
    end);
end;

function TMiletusRegistry.DeleteValue(const Name: string): TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    var
      o: TJSONObject;
    begin
      FReadValueProc :=
      procedure()
      begin
        if FReject then
          AReject(False)
        else
          AResolve(FReadBoolRes);
      end;
      o := TJSONObject.Create;
      o.AddPair('GUID', FGUID);
      o.AddPair('RootKey', FRootKey);
      o.AddPair('Access', IntToStr(FAccess));
      o.AddPair('NameOrIdent', Name);
      SendMessageToMiletus(NM_REG_VALUE_DELETE, o);
    end);
end;

destructor TMiletusRegistry.Destroy;
var
  o: TJSONObject;
begin
  o := TJSONObject.Create;
  o.AddPair('GUID', FGUID);
  SendMessageToMiletus(NM_REG_DESTROY, o);
  FObserver.Free;
  FReadStringsRes.Free;
  inherited;
end;

procedure TMiletusRegistry.DoObserverChange(Sender: TObject;
  AMessageID: Integer; AData: TJSONValue);
var
  i: Integer;
  s: string;
  arr: TJSONArray;
begin
  FReject := False;
  s := TJSONObject(AData).GetJSONValue('GUID');
  if (s = '') or (FGUID <> s) then
    Exit;

  if AMessageID = NM_REG_READ then
  begin
    i := StrToInt(TJSONObject(AData).GetJSONValue('Type'));
    case i of
      IR_BOOL:
      begin
        s := TJSONObject(AData).GetJSONValue('Value');
        FReadBoolRes := StrToBool(s);
      end;
      IR_DATE:
      begin
        s := TJSONObject(AData).GetJSONValue('Value');
        TryRFC3339ToDateTime(s, FReadDateTimeRes);
      end;
      IR_DATETIME:
      begin
        s := TJSONObject(AData).GetJSONValue('Value');
        TryRFC3339ToDateTime(s, FReadDateTimeRes);
      end;
      IR_FLOAT:
      begin
        s := TJSONObject(AData).GetJSONValue('Value');
        FReadFloatRes := StrToFloat(s);
      end;
      IR_INTEGER:
      begin
        s := TJSONObject(AData).GetJSONValue('Value');
        FReadIntegerRes := StrToInt(s);
      end;
      IR_STRING:
      begin
        s := TJSONObject(AData).GetJSONValue('Value');
        FReadStringRes := s;
      end;
      IR_TIME:
      begin
        s := TJSONObject(AData).GetJSONValue('Value');
        TryRFC3339ToDateTime(s, FReadDateTimeRes);
      end;
      IR_BINARY_STREAM:
      begin
        arr := TJSONArray(TJSONObject(AData).GetValue('Value'));
        s := arr.Items[0].Value;
        FReadIntegerRes := StrToInt(s);
        SetLength(FReadBytesRes, arr.Count - 1);
        for I := 1 to arr.Count - 1 do
          FReadBytesRes[I - 1] := StrToInt(arr.Items[I].Value);
      end;
    end;

    if Assigned(FReadValueProc) then
      FReadValueProc;
  end
  else if (AMessageID = NM_REG_KEY_OPEN) or (AMessageID = NM_REG_KEY_CREATE) or (AMessageID = NM_REG_KEY_DELETE) or (AMessageID = NM_REG_KEY_EXISTS) or (AMessageID = NM_REG_VALUE_EXISTS) or (AMessageID = NM_REG_VALUE_DELETE) then
  begin
    s := TJSONObject(AData).GetJSONValue('Result');
    FReadBoolRes := StrToBool(s);
    if Assigned(FReadValueProc) then
      FReadValueProc;
  end
  else if AMessageID = NM_REG_KEY_CLOSE then
  begin
    if Assigned(FReadValueProc) then
      FReadValueProc;
  end
  else if AMessageID = NM_REG_OP_REJECT then
  begin
    FReject := True;
    if Assigned(FReadValueProc) then
      FReadValueProc;
  end;
end;

procedure TMiletusRegistry.InternalWriteValue(const AType: Integer;
  const NameOrIdent: string; Value: JSValue);
var
  o: TJSONObject;
begin
  o := TJSONObject.Create;
  o.AddPair('GUID', FGUID);
  o.AddPair('RootKey', FRootKey);
  o.AddPair('Access', IntToStr(FAccess));
  o.AddPair('Type', AType);
  o.AddPair('NameOrIdent', NameOrIdent);
  o.AddPair('Value', TJSONValue.Create(Value));
  SendMessageToMiletus(NM_REG_WRITE, o);
end;

function TMiletusRegistry.KeyExists(const Key: string): TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    var
      o: TJSONObject;
    begin
      FReadValueProc :=
      procedure()
      begin
        if FReject then
          AReject(False)
        else
          AResolve(FReadBoolRes);
      end;
      o := TJSONObject.Create;
      o.AddPair('GUID', FGUID);
      o.AddPair('RootKey', FRootKey);
      o.AddPair('Key', Key);
      SendMessageToMiletus(NM_REG_KEY_EXISTS, o);
    end);
end;

function TMiletusRegistry.OpenKey(const Key: string;
  CanCreate: Boolean): TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    var
      o: TJSONObject;
    begin
      FReadValueProc :=
      procedure()
      begin
        if FReject then
          AReject(False)
        else
          AResolve(FReadBoolRes);
      end;
      o := TJSONObject.Create;
      o.AddPair('GUID', FGUID);
      o.AddPair('RootKey', FRootKey);
      o.AddPair('Access', IntToStr(FAccess));
      o.AddPair('Key', Key);
      o.AddPair('CanCreate', TJSONValue.Create(CanCreate));
      SendMessageToMiletus(NM_REG_KEY_OPEN, o);
    end);
end;

function TMiletusRegistry.ReadBinaryData(const Name: string; var Buffer: TBytes;
  BufSize: Integer): TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    var
      o: TJSONObject;
      I: Integer;
    begin
      FReadValueProc :=
      procedure()
      begin
        if FReject then
          AReject(-1)
        else
        begin
          SetLength(Buffer, BufSize);
          for I := 0 to BufSize do
            Buffer[I] := FReadBytesRes[I];
          AResolve(FReadIntegerRes);
        end;
      end;
      o := TJSONObject.Create;
      o.AddPair('GUID', FGUID);
      o.AddPair('RootKey', FRootKey);
      o.AddPair('Access', IntToStr(FAccess));
      o.AddPair('Type', IR_BINARY_STREAM);
      o.AddPair('NameOrIdent', Name);
      o.AddPair('BufferSize', BufSize);
      SendMessageToMiletus(NM_REG_READ, o);
    end);
end;

function TMiletusRegistry.ReadBool(const Name: string): TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    var
      o: TJSONObject;
    begin
      FReadValueProc :=
      procedure()
      begin
        if FReject then
          AReject(False)
        else
          AResolve(FReadBoolRes);
      end;
      o := TJSONObject.Create;
      o.AddPair('GUID', FGUID);
      o.AddPair('RootKey', FRootKey);
      o.AddPair('Access', IntToStr(FAccess));
      o.AddPair('Type', IR_BOOL);
      o.AddPair('NameOrIdent', Name);
      SendMessageToMiletus(NM_REG_READ, o);
    end);
end;

function TMiletusRegistry.ReadCurrency(const Name: string): TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    var
      o: TJSONObject;
    begin
      FReadValueProc :=
      procedure()
      begin
        if FReject then
          AReject(0)
        else
          AResolve(FReadFloatRes);
      end;
      o := TJSONObject.Create;
      o.AddPair('GUID', FGUID);
      o.AddPair('RootKey', FRootKey);
      o.AddPair('Access', IntToStr(FAccess));
      o.AddPair('Type', IR_CURRENCY);
      o.AddPair('NameOrIdent', Name);
      SendMessageToMiletus(NM_REG_READ, o);
    end);
end;

function TMiletusRegistry.ReadDate(const Name: string): TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    var
      o: TJSONObject;
    begin
      FReadValueProc :=
      procedure()
      begin
        if FReject then
          AReject(0)
        else
          AResolve(FReadDateTimeRes);
      end;
      o := TJSONObject.Create;
      o.AddPair('GUID', FGUID);
      o.AddPair('RootKey', FRootKey);
      o.AddPair('Access', IntToStr(FAccess));
      o.AddPair('Type', IR_DATE);
      o.AddPair('NameOrIdent', Name);
      SendMessageToMiletus(NM_REG_READ, o);
    end);
end;

function TMiletusRegistry.ReadDateTime(const Name: string): TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    var
      o: TJSONObject;
    begin
      FReadValueProc :=
      procedure()
      begin
        if FReject then
          AReject(0)
        else
          AResolve(FReadDateTimeRes);
      end;
      o := TJSONObject.Create;
      o.AddPair('GUID', FGUID);
      o.AddPair('RootKey', FRootKey);
      o.AddPair('Access', IntToStr(FAccess));
      o.AddPair('Type', IR_DATETIME);
      o.AddPair('NameOrIdent', Name);
      SendMessageToMiletus(NM_REG_READ, o);
    end);
end;

function TMiletusRegistry.ReadFloat(const Name: string): TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    var
      o: TJSONObject;
    begin
      FReadValueProc :=
      procedure()
      begin
        if FReject then
          AReject(0)
        else
          AResolve(FReadFloatRes);
      end;
      o := TJSONObject.Create;
      o.AddPair('GUID', FGUID);
      o.AddPair('RootKey', FRootKey);
      o.AddPair('Access', IntToStr(FAccess));
      o.AddPair('Type', IR_FLOAT);
      o.AddPair('NameOrIdent', Name);
      SendMessageToMiletus(NM_REG_READ, o);
    end);
end;

function TMiletusRegistry.ReadInteger(const Name: string): TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    var
      o: TJSONObject;
    begin
      FReadValueProc :=
      procedure()
      begin
        if FReject then
          AReject(-1)
        else
          AResolve(FReadIntegerRes);
      end;
      o := TJSONObject.Create;
      o.AddPair('GUID', FGUID);
      o.AddPair('RootKey', FRootKey);
      o.AddPair('Access', IntToStr(FAccess));
      o.AddPair('Type', IR_INTEGER);
      o.AddPair('NameOrIdent', Name);
      SendMessageToMiletus(NM_REG_READ, o);
    end);
end;

function TMiletusRegistry.ReadString(const Name: string): TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    var
      o: TJSONObject;
    begin
      FReadValueProc :=
      procedure()
      begin
        if FReject then
          AReject('')
        else
          AResolve(FReadStringRes);
      end;
      o := TJSONObject.Create;
      o.AddPair('GUID', FGUID);
      o.AddPair('RootKey', FRootKey);
      o.AddPair('Access', IntToStr(FAccess));
      o.AddPair('Type', IR_STRING);
      o.AddPair('NameOrIdent', Name);
      SendMessageToMiletus(NM_REG_READ, o);
    end);
end;

function TMiletusRegistry.ReadTime(const Name: string): TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    var
      o: TJSONObject;
    begin
      FReadValueProc :=
      procedure()
      begin
        if FReject then
          AReject(0)
        else
          AResolve(FReadDateTimeRes);
      end;
      o := TJSONObject.Create;
      o.AddPair('GUID', FGUID);
      o.AddPair('RootKey', FRootKey);
      o.AddPair('Access', IntToStr(FAccess));
      o.AddPair('Type', IR_TIME);
      o.AddPair('NameOrIdent', Name);
      SendMessageToMiletus(NM_REG_READ, o);
    end);
end;

function TMiletusRegistry.ValueExists(const Name: string): TJSPromise;
begin
  Result := TJSPromise.New(
    procedure(AResolve, AReject: TJSPromiseResolver)
    var
      o: TJSONObject;
    begin
      FReadValueProc :=
      procedure()
      begin
        if FReject then
          AReject(False)
        else
          AResolve(FReadBoolRes);
      end;
      o := TJSONObject.Create;
      o.AddPair('GUID', FGUID);
      o.AddPair('RootKey', FRootKey);
      o.AddPair('NameOrIdent', Name);
      SendMessageToMiletus(NM_REG_VALUE_EXISTS, o);
    end);
end;

procedure TMiletusRegistry.WriteBinaryData(const Name: string; const Buffer: TBytes;
  BufSize: Integer);
var
  o: TJSONObject;
begin
  o := TJSONObject.Create;
  o.AddPair('GUID', FGUID);
  o.AddPair('RootKey', FRootKey);
  o.AddPair('Access', IntToStr(FAccess));
  o.AddPair('Type', IR_BINARY_STREAM);
  o.AddPair('NameOrIdent', Name);
  o.AddPair('Value', TJSONValue.Create(Buffer));
  o.AddPair('BufferSize', TJSONValue.Create(BufSize));
  SendMessageToMiletus(NM_REG_WRITE, o);
end;

procedure TMiletusRegistry.WriteBool(const Name: string; Value: Boolean);
begin
  InternalWriteValue(IR_BOOL, Name, Value);
end;

procedure TMiletusRegistry.WriteCurrency(const Name: string; Value: Currency);
begin
  InternalWriteValue(IR_CURRENCY, Name, Value);
end;

procedure TMiletusRegistry.WriteDate(const Name: string; Value: TDateTime);
begin
  InternalWriteValue(IR_DATE, Name, DateTimeToRFC3339(Value));
end;

procedure TMiletusRegistry.WriteDateTime(const Name: string; Value: TDateTime);
begin
  InternalWriteValue(IR_DATETIME, Name, DateTimeToRFC3339(Value));
end;

procedure TMiletusRegistry.WriteExpandString(const Name, Value: string);
begin
  InternalWriteValue(IR_EXPAND_STRING, Name, Value);
end;

procedure TMiletusRegistry.WriteFloat(const Name: string; Value: Double);
begin
  InternalWriteValue(IR_FLOAT, Name, Value);
end;

procedure TMiletusRegistry.WriteInteger(const Name: string; Value: Integer);
begin
  InternalWriteValue(IR_INTEGER, Name, Value);
end;

procedure TMiletusRegistry.WriteString(const Name, Value: string);
begin
  InternalWriteValue(IR_STRING, Name, Value);
end;

procedure TMiletusRegistry.WriteTime(const Name: string; Value: TDateTime);
begin
  InternalWriteValue(IR_TIME, Name, DateTimeToRFC3339(Value));
end;

constructor TMiletusRegistry.Create;
begin
  Create(KEY_ALL_ACCESS);
end;

{ TMiletusUpdate }

function TMiletusUpdate.DoUpdate: TJSPromise;
begin
  Result := TJSPromise.New(
  procedure(AResolve, AReject: TJSPromiseResolver)
  var
    o: TJSONObject;
	begin
    if FURL = '' then
      raise Exception.Create('URL cannot be empty.');

    TTMSMiletusHelperRec.FMiletusUpdateProc :=
	  procedure()
	  begin
	    AResolve(nil);
	  end;

    o := TJSONObject.Create;
    o.AddPair('URL', FURL);
    o.AddPair('Enable', TJSONValue.Create(FEnable));

    if FUsername <> '' then
      o.AddPair('Username', FUsername);

    if FPassword <> '' then
      o.AddPair('Password', FPassword);

    if FLogFilename <> '' then
      o.AddPair('Filename', FLogFilename);

    SendMessageToMiletus(NM_UPDATE_START, o);
	end);
end;

function TMiletusUpdate.NewVersionAvailable: TJSPromise;
begin
  Result := TJSPromise.New(
  procedure(AResolve, AReject: TJSPromiseResolver)
  var
    o: TJSONObject;
	begin
    if FURL = '' then
      raise Exception.Create('URL cannot be empty.');

    o := TJSONObject.Create;
    o.AddPair('URL', FURL);
    o.AddPair('Enable', TJSONValue.Create(FEnable));

    if FUsername <> '' then
      o.AddPair('Username', FUsername);

    if FPassword <> '' then
      o.AddPair('Password', FPassword);

    if FLogFilename <> '' then
      o.AddPair('Filename', FLogFilename);

	  TTMSMiletusHelperRec.FMiletusUpdateProc :=
	  procedure()
	  begin
	    AResolve(TTMSMiletusHelperRec.FMiletusUpdateRes); //boolean
	  end;
	  SendMessageToMiletus(NM_UPDATE_CHECK, o);
	end);
end;

procedure TMiletusUpdate.Restart;
begin
  SendMessageToMiletus(NM_UPDATE_RESTART, TJSONObject.Create);
end;

initialization
begin
  InitJS;
  MiletusCommunication := TMiletusCommunication.Create;
  MiletusCommunication.ReceiveMessageFromMiletus(''); //Dummy
  TTMSParams.Execute;
  SendMessageToMiletus(NM_WINDOW_READY, TJSONObject.Create);
end;

end.
